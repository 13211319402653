import {
  LucidchartDocumentAnalyzeComponent,
  LucidchartDocumentAnalyzeReference,
  LucidchartDocumentAnalyzeResponse,
} from '@ardoq/api-types/integrations';
import {
  ComponentsMapping,
  MappingsState,
  ReferencesMapping,
} from '../mappings/types';
import fp from 'lodash/fp';
import { WorkspacesMetadata } from '../../types';

const keyByWithId = <
  Entity extends
    | LucidchartDocumentAnalyzeReference
    | LucidchartDocumentAnalyzeComponent,
>(
  entities: Entity[]
) =>
  fp.flow(
    fp.entries,
    fp.map(([id, value]) => ({ ...value, id })),
    fp.keyBy('id')
  )(entities) as unknown as Record<string, Entity & { id: string }>;

export const analyzeResponseToMappings = (
  workspaceMetadata: WorkspacesMetadata,
  response: LucidchartDocumentAnalyzeResponse
): Pick<MappingsState, 'components' | 'references'> => {
  const componentTypesMap = Object.fromEntries(
    workspaceMetadata
      .map(({ workspace, model }) =>
        Object.entries(model.root).map(([key, { name }]) => [
          name.toLowerCase(),
          { workspaceId: workspace._id, value: key },
        ])
      )
      .flat()
  );

  const componentsMappings: ComponentsMapping = keyByWithId(
    response.components.map(component => ({
      ...component,
      componentTypeId: componentTypesMap[component.type.toLowerCase()]?.value,
      workspaceId: componentTypesMap[component.type.toLowerCase()]?.workspaceId,
    }))
  );
  const componentMappingsValues = Object.values(componentsMappings);

  const workspaceToReferenceTypesMap = workspaceMetadata.reduce(
    (acc, { workspace, model }) => {
      return {
        ...acc,
        [workspace._id]: Object.entries(model.referenceTypes).map(
          ([key, { name }]) => ({
            name,
            refType: key,
          })
        ),
      };
    },
    {} as Record<string, Array<{ refType: string; name: string }>>
  );

  const referencesMappings: ReferencesMapping = keyByWithId(
    response.references.map(reference => {
      const sourceComponent = componentMappingsValues.find(comp => {
        return comp.name === reference.source;
      });
      const targetComponent = componentMappingsValues.find(comp => {
        return comp.name === reference.target;
      });

      const referenceTypes =
        workspaceToReferenceTypesMap[sourceComponent?.workspaceId ?? ''] ?? [];

      return {
        ...reference,
        referenceTypeId: referenceTypes.find(
          ({ name }) => name.toLowerCase() === reference.type.toLowerCase()
        )?.refType,
        sourceComponentId: sourceComponent?.id,
        targetComponentId: targetComponent?.id,
      };
    })
  );

  return {
    components: componentsMappings,
    references: referencesMappings,
  };
};
