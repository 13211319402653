import {
  collectRoutines,
  dispatchAction,
  extractPayload,
  ofType,
  routine,
} from '@ardoq/rxbeach';
import {
  generateValueProposition,
  generateValueStream,
  setLink,
  setValueProposition,
  submitValuePropositionModal,
  submitValueStreamModal,
  createValuePropositionComponent,
  showValueProposition,
  generateBusinessCapabilityMap,
  submitBusinessCapabilityMapModal,
  generateBCMLinking,
  submitBCMLinkingModal,
} from './actions';
import { filter, switchMap, tap } from 'rxjs';
import { api, handleError, augmentationsApi } from '@ardoq/api';
import { trackEvent } from 'tracking/tracking';
import { getUseCase } from '../actions';
import { UseCaseStatus } from '@ardoq/api-types';
import { loadScenarioModuleRoute } from 'router/navigationActions';
import { ViewIds } from '@ardoq/api-types';
import { loadWorkspaceModuleRoute } from 'router/navigationActions';

const navigateToScenario = (scenarioId: string) => {
  dispatchAction(
    loadScenarioModuleRoute({
      scenarioId,
      activeFiltersQueryString: '',
      mainViewId: ViewIds.SWIMLANE,
      sort: {
        attr: '_order',
        order: 0,
      },
      referenceId: null,
      componentId: null,
      perspectiveId: null,
      presentationId: null,
      secondaryViewId: null,
    })
  );
};

const navigateToWorkspace = (workspaceId: string) => {
  dispatchAction(
    loadWorkspaceModuleRoute({
      workspaceIds: [workspaceId],
      workspaceId,
      componentId: null,
      referenceId: null,
      mainViewId: ViewIds.SWIMLANE,
      secondaryViewId: null,
      isGridEditorShown: false,
      presentationId: null,
      sort: {
        order: 0,
        attr: '_order',
      },
      perspectiveId: null,
      activeFiltersQueryString: '',
      loadedState: [],
      selectedSlideId: null,
      hierarchiesWorkspaceIds: [],
    })
  );
};

const handleSubmitBCMLinkingModal = routine(
  ofType(submitBCMLinkingModal),
  extractPayload(),
  switchMap(async ({ link, closeModal }) => {
    closeModal(true);
    navigateToScenario(link);
  })
);
const handleGenerateBCMLinking = routine(
  ofType(generateBCMLinking),
  extractPayload(),
  filter(({ company, description, valueProposition, industry }) =>
    Boolean(company && description && valueProposition && industry)
  ),
  switchMap(async ({ company, description, valueProposition, industry }) => {
    return augmentationsApi.submitLinking({
      name: company,
      description,
      industry,
      valueProposition,
    });
  }),
  handleError(ardoqError => {
    dispatchAction(setLink(undefined));
    api.logErrorIfNeeded(ardoqError);
  }),
  tap(response => {
    dispatchAction(setLink(response._id));
  })
);
const handleSubmitBusinessCapabilityMapModal = routine(
  ofType(submitBusinessCapabilityMapModal),
  extractPayload(),
  switchMap(async ({ link, closeModal }) => {
    closeModal(true);
    navigateToScenario(link);
  })
);
const handleGenerateBusinessCapabilityMap = routine(
  ofType(generateBusinessCapabilityMap),
  extractPayload(),
  filter(({ company, description, valueProposition, industry }) =>
    Boolean(company && description && valueProposition && industry)
  ),
  switchMap(async ({ company, description, valueProposition, industry }) => {
    return augmentationsApi.submitBusinessCapabilityMap({
      name: company,
      description,
      industry,
      valueProposition,
    });
  }),
  handleError(ardoqError => {
    dispatchAction(setLink(undefined));
    api.logErrorIfNeeded(ardoqError);
  }),
  tap(response => {
    dispatchAction(setLink(response._id));
  })
);
const handleSubmitValueProposition = routine(
  ofType(submitValuePropositionModal),
  extractPayload(),
  switchMap(async ({ link, closeModal }) => {
    closeModal(true);
    navigateToWorkspace(link);
  })
);
const handleGenrateValuePropositionComponent = routine(
  ofType(createValuePropositionComponent),
  extractPayload(),
  filter(({ valueProposition }) => Boolean(valueProposition)),
  switchMap(async ({ valueProposition, useCaseId }) => {
    const returned = await augmentationsApi.submitValueProposition({
      description: valueProposition,
    });
    if (useCaseId)
      dispatchAction(
        getUseCase({
          id: useCaseId,
          status: UseCaseStatus.IN_PROGRESS,
        })
      );
    return returned;
  }),
  handleError(ardoqError => {
    dispatchAction(setLink(undefined));
    api.logErrorIfNeeded(ardoqError);
  }),
  tap(response => {
    dispatchAction(setLink(response.rootWorkspace));
  })
);

const handleSubmitValueStream = routine(
  ofType(submitValueStreamModal),
  extractPayload(),
  switchMap(async ({ link, closeModal }) => {
    closeModal(true);
    navigateToScenario(link);
  })
);
const handleGenerateValueStream = routine(
  ofType(generateValueStream),
  extractPayload(),
  filter(({ company, description, valueProposition, industry }) =>
    Boolean(company && description && valueProposition && industry)
  ),
  switchMap(async ({ company, description, valueProposition, industry }) => {
    return augmentationsApi.submitValueStream({
      name: company,
      description,
      industry,
      valueProposition,
    });
  }),
  handleError(ardoqError => {
    dispatchAction(setLink(undefined));
    api.logErrorIfNeeded(ardoqError);
  }),
  tap(response => {
    dispatchAction(setLink(response._id));
  })
);

const handleGenerateValueProposition = routine(
  ofType(generateValueProposition),
  extractPayload(),
  filter(({ company, description }) => Boolean(company && description)),
  switchMap(async ({ company, description }) => {
    const response = await augmentationsApi.generateDescription({
      name: company,
      description: description,
    });
    trackEvent(
      'Generate value proposition in value propoition modal in core app'
    );
    return response;
  }),
  handleError(ardoqError => {
    dispatchAction(setValueProposition('Error'));
    dispatchAction(showValueProposition());
    api.logErrorIfNeeded(ardoqError);
  }),
  tap(response => {
    dispatchAction(showValueProposition());
    if (response?.type === 'description') {
      dispatchAction(setValueProposition(response.text));
    } else {
      dispatchAction(setValueProposition('Error'));
      trackEvent(
        'Generate value proposition LLM could not generate description',
        {
          reason: response.text,
        }
      );
    }
  })
);

export const augmentationModalRoutines = collectRoutines(
  handleSubmitValueProposition,
  handleGenerateValueProposition,
  handleSubmitValueStream,
  handleGenerateValueStream,
  handleGenrateValuePropositionComponent,
  handleSubmitBusinessCapabilityMapModal,
  handleGenerateBusinessCapabilityMap,
  handleSubmitBCMLinkingModal,
  handleGenerateBCMLinking
);
