import {
  augmentationModal$,
  AugmentationModalState,
} from './augmentationModal$';
import { connect, dispatchAction } from '@ardoq/rxbeach';
import { map } from 'rxjs';
import {
  setCompany,
  setDescription,
  setIndustry,
  setValueProposition,
  generateBCMLinking,
  submitBCMLinkingModal,
} from './actions';
import AugmentationModal from './AugmentationModal';

type BCMLinkingModalViewProps = {
  company: string;
  setCompany: (value: string) => void;
  description: string;
  setDescription: (value: string) => void;
  industry: string;
  setIndustry: (value: string) => void;
  valueProposition: string;
  setValueProposition: (value: string) => void;
  loading: boolean;
  handleSubmit: (closeModal: () => void) => void;
  handleGenerate: () => void;
  closeModal: () => void;
  disableSubmit: boolean;
  disableGenerate: boolean;
  isGenerationError: boolean;
  isGenerationSuccess: boolean;
};

const BCMLinkingModalView = (props: BCMLinkingModalViewProps) => {
  return (
    <AugmentationModal
      simpleValueProposition
      {...props}
      modalDescription="Generate references showing how the Organization's overall Value Stream is enabled by Business Capabilities. The result is created as a Scenario that can be adjusted before saving."
      submitText="Open Scenario"
      generateName="Links"
    />
  );
};

const toProps = (
  state: AugmentationModalState
): Omit<BCMLinkingModalViewProps, 'closeModal'> => ({
  company: state.company,
  setCompany: company => {
    dispatchAction(setCompany(company));
  },
  description: state.description,
  setDescription: description => {
    dispatchAction(setDescription(description));
  },
  industry: state.industry,
  setIndustry: industry => {
    dispatchAction(setIndustry(industry));
  },
  valueProposition: state.valueProposition,
  setValueProposition: vp => {
    dispatchAction(setValueProposition(vp));
  },
  loading: state.loading,
  disableSubmit: state.disableSubmit,
  disableGenerate: state.disableGenerate,
  handleSubmit: closeModal => {
    dispatchAction(
      submitBCMLinkingModal({
        closeModal,
        link: state.generationLink,
      })
    );
  },
  handleGenerate: () => {
    dispatchAction(
      generateBCMLinking({
        company: state.company,
        description: state.description,
        valueProposition: state.valueProposition,
        industry: state.industry,
      })
    );
  },
  isGenerationSuccess: Boolean(state.generationLink),
  isGenerationError: state.generationError,
});

export default connect(
  BCMLinkingModalView,
  augmentationModal$.pipe(map(toProps))
);
