import { Box, FlexBox } from '@ardoq/layout';
import styled from 'styled-components';
import { connect } from '@ardoq/rxbeach';
import { Icon, IconSize } from '@ardoq/icons';
import { colors, radius, space } from '@ardoq/design-tokens';
import { contextToolBar$ } from './contextToolBar$';
import { ContextToolBarAction, CurrentToolbarContext } from './types';
import CurrentSelection from './CurrentSelection';

const Container = styled(Box)`
  position: absolute;
  bottom: 1rem;
  left: 50%;
  transform: translateX(-50%);
`;

// The DS have no vertically stacking buttons. If this is something we want to
// support, we should add a new component to the DS instead of this
const ToolButton = styled.button`
  background-color: ${colors.bgDefault};
  padding: ${space.xsmall} ${space.medium};
  border: none;
  border-radius: ${radius.small};
  display: flex;
  flex-flow: column;
  white-space: nowrap;
  align-items: center;
  gap: ${space.xxsmall};
  cursor: pointer;
`;

type ContextToolBarProps = {
  currentContext: CurrentToolbarContext | null;
  actions: ContextToolBarAction[];
  showContextSummary: boolean;
};

const ContextToolBar = ({
  currentContext,
  actions,
  showContextSummary,
}: ContextToolBarProps) => {
  return (
    <Container
      borderRadius="small"
      borderColor="borderSubtle00"
      backgroundColor="bgDefault"
      padding="small"
    >
      {showContextSummary && currentContext && (
        <CurrentSelection currentContext={currentContext} />
      )}
      <FlexBox
        align="center"
        gap="xsmall"
        backgroundColor="bgSubtle"
        padding="xsmall"
        borderRadius="small"
      >
        {actions.map(action => (
          <ToolButton
            disabled={action.disabled}
            key={action.label}
            onClick={action.onClick}
          >
            <Icon
              iconName={action.icon}
              color={colors.iconInfo}
              size={IconSize.SMALL}
            />
            {action.label}
          </ToolButton>
        ))}
      </FlexBox>
    </Container>
  );
};

export default connect(ContextToolBar, contextToolBar$);
