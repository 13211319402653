import { actionCreator } from '@ardoq/rxbeach';
import { addPrefix } from '../actions';

export const setCompany = actionCreator<string>(addPrefix('SET_COMPANY'));
export const setDescription = actionCreator<string>(
  addPrefix('SET_DESCRIPTION')
);
export const setValueProposition = actionCreator<string>(
  addPrefix('SET_VALUE_PROPOSITION')
);
export const showValueProposition = actionCreator<void>(
  addPrefix('SHOW_VALUE_PROPOSITION')
);
export const setIndustry = actionCreator<string>(addPrefix('SET_INDUSTRY'));
export const setLink = actionCreator<string | undefined>(addPrefix('SET_LINK'));

export const submitValuePropositionModal = actionCreator<{
  link: string;
  closeModal: (success: boolean) => void;
}>(addPrefix('SUBMIT_VALUE_PROPOSITION_MODAL'));

export const submitValueStreamModal = actionCreator<{
  link: string;
  closeModal: (success: boolean) => void;
}>(addPrefix('SUBMIT_VALUE_STREAM_MODAL'));

export const generateValueProposition = actionCreator<{
  company: string;
  description: string;
}>(addPrefix('GENERATE_VALUE_PROPOSITION'));

export const createValuePropositionComponent = actionCreator<{
  valueProposition: string;
  useCaseId: string | null;
}>(addPrefix('CREATE_VALUE_PROPOSITION_COMPONENT'));

export const generateValueStream = actionCreator<{
  company: string;
  description: string;
  valueProposition: string;
  industry: string;
}>(addPrefix('GENERATE_VALUE_STREAM'));

export const generateBusinessCapabilityMap = actionCreator<{
  company: string;
  description: string;
  valueProposition: string;
  industry: string;
}>(addPrefix('GENERATE_BUSINESS_CAPABILITY_MAP'));

export const submitBusinessCapabilityMapModal = actionCreator<{
  link: string;
  closeModal: (success: boolean) => void;
}>(addPrefix('SUBMIT_BUSINESS_CAPABILITY_MAP_MODAL'));

export const generateBCMLinking = actionCreator<{
  company: string;
  description: string;
  valueProposition: string;
  industry: string;
}>(addPrefix('GENERATE_BCM_LINKING'));

export const submitBCMLinkingModal = actionCreator<{
  link: string;
  closeModal: (success: boolean) => void;
}>(addPrefix('SUBMIT_BCM_LINKING_MODAL'));
