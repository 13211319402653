import React, { useRef, useState } from 'react';
import { MenuWrapper, Section, SidebarPositioner, Wrapper } from './atoms';
import AppMainSidebarButton from './AppMainSidebarButton';
import appMainSidebar$ from './appMainSidebar$';
import { AppMainSidebarProps } from './types';
import { connect } from '@ardoq/rxbeach';
import { Features, hasFeature } from '@ardoq/features';
import Logo from 'views/Logo';

const SidebarContent = ({
  hasNewJourneyFeature,
  topSectionButtons,
  bottomSectionButtons,
}: AppMainSidebarProps) => {
  const [isHovered, setIsHovered] = useState(false);
  const isEnteredRef = useRef<boolean>(false);

  const expandMenu = () => setIsHovered(true);
  const collapseMenu = () => setIsHovered(false);

  const onMouseLeave = () => {
    isEnteredRef.current = false;
    collapseMenu();
  };

  const onMouseEnter = () => {
    if (isEnteredRef.current) {
      return;
    }

    isEnteredRef.current = true;
    expandMenu();
  };

  const isExpanded = hasNewJourneyFeature && isHovered;

  return (
    <Wrapper
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      $hasNewJourneyFeature={hasNewJourneyFeature}
      $isExpanded={isExpanded}
    >
      {hasNewJourneyFeature && <Logo shouldUseNewJourneyVersion={true} />}
      <MenuWrapper
        as="nav"
        aria-label="Main navigation"
        $hasNewJourneyFeature={hasNewJourneyFeature}
      >
        <Section as="section" $hasNewJourneyFeature={hasNewJourneyFeature}>
          {topSectionButtons.map(button => (
            <AppMainSidebarButton
              {...button}
              {...(button.isActive ? { 'aria-current': 'page' } : {})}
              key={button.iconName}
              onClick={() => {
                collapseMenu();
                button.onClick?.();
              }}
              onMouseEnter={expandMenu}
              clearHover={onMouseLeave}
              sidebarIsExpanded={isExpanded}
            />
          ))}
        </Section>
        <Section as="section" $hasNewJourneyFeature={hasNewJourneyFeature}>
          {bottomSectionButtons.map(button => (
            <AppMainSidebarButton
              {...button}
              {...(button.isActive ? { 'aria-current': 'page' } : {})}
              key={button.iconName}
              onClick={() => {
                collapseMenu();
                button.onClick?.();
              }}
              onMouseEnter={expandMenu}
              clearHover={onMouseLeave}
              sidebarIsExpanded={isExpanded}
            />
          ))}
        </Section>
      </MenuWrapper>
    </Wrapper>
  );
};

const AppMainSidebar = (props: AppMainSidebarProps) => {
  const hasNewJourneyFeature = hasFeature(Features.NEW_CORE_JOURNEY);
  return (
    <SidebarPositioner $hasNewJourneyFeature={hasNewJourneyFeature}>
      <SidebarContent {...props} />
    </SidebarPositioner>
  );
};

export const ConnectedAppMainSidebar = connect(AppMainSidebar, appMainSidebar$);
