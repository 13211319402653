import { modal, ModalLayout, ModalSize, ModalTemplate } from '@ardoq/modal';
import { capitalize } from '@ardoq/common-helpers';
import { Link, Paragraph } from '@ardoq/typography';
import { DefaultNotification } from '@ardoq/status-ui';
import { Stack } from '@ardoq/layout';
import styled from 'styled-components';
import { dispatchAction, connect } from '@ardoq/rxbeach';
import { showSupport } from 'integrations/actions';
import {
  TransferDirection,
  TransferRequestPayload,
} from 'integrations/common/streams/transferState/types';
import { viewModel$ } from './viewModel$';

const DefaultNotificationWithOverflow = styled(DefaultNotification)`
  overflow: auto;
  max-height: 580px;
`;

const ConfigDetails = styled.div`
  font-family: monospace;
  white-space: pre;
`;

type ImportErrorModalComponentProps = {
  onSubmitCompleted: VoidFunction;
  onCancel: VoidFunction;
  errorMessage: string;
  config: TransferRequestPayload;
  integrationName: string;
  transferDirection: TransferDirection;
  errorData?: string;
};

const ImportErrorModalComponent = ({
  onSubmitCompleted,
  onCancel,
  errorMessage,
  config,
  integrationName,
  transferDirection,
  errorData,
}: ImportErrorModalComponentProps) => {
  const handleSendToSupport = () => {
    dispatchAction(
      showSupport({
        message: `Help! There was a technical issue with my ${integrationName} ${transferDirection}`,
      })
    );
    onSubmitCompleted();
  };

  return (
    <ModalTemplate
      headerText={`${capitalize(transferDirection)} error report`}
      subtitle="Use the report to troubleshoot or send it to support to fix the issue."
      modalSize={ModalSize.M}
      primaryButtonText="Send to support"
      secondaryButtonText="Close"
      onPrimaryButtonClick={handleSendToSupport}
      onSecondaryButtonClick={onCancel}
    >
      <ModalLayout>
        <Paragraph variant="text1">
          We securely handle your data:{' '}
          <Link
            href="https://ardoq.com/privacy"
            target="_blank"
            rel="noopener noreferrer"
          >
            Privacy Notice
          </Link>
          .
        </Paragraph>
        <DefaultNotificationWithOverflow>
          <Stack gap="medium">
            <Paragraph variant="text2">Error message:</Paragraph>
            <Paragraph variant="text2Bold">{errorMessage}</Paragraph>
            {errorData && (
              <>
                <Paragraph variant="text2">Error data: </Paragraph>
                <Paragraph variant="text2Bold">{errorData}</Paragraph>
              </>
            )}
            <Paragraph variant="text2">
              {capitalize(transferDirection)} request payload:
            </Paragraph>
            <ConfigDetails>{JSON.stringify(config, null, 2)}</ConfigDetails>
          </Stack>
        </DefaultNotificationWithOverflow>
      </ModalLayout>
    </ModalTemplate>
  );
};

const ImportErrorModal = connect(ImportErrorModalComponent, viewModel$);

export const startImportErrorModal = () =>
  modal<boolean>(
    resolve => (
      <ImportErrorModal
        onSubmitCompleted={() => resolve(true)}
        onCancel={() => resolve(false)}
      />
    ),
    {
      autoFocus: true,
      shouldCloseOnEscapeKey: true,
      shouldCloseOnBackdropClick: false,
    }
  );
