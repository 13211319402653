import { dispatchAction } from '@ardoq/rxbeach';
import {
  deregisterInventoryGridApi,
  registerInventoryGridApi,
} from './inventoryGridApi$';
import {
  InventoryCommands,
  InventoryDatasourceSelection,
  UpdateComponentFieldValue,
  CreateComponentParam,
  UpdateComponentFieldValueParam,
  SelectedComponent,
  InventoryGridApi,
} from '@ardoq/inventory';

import { componentApi } from '@ardoq/api';
import {
  getArdoqErrorMessage,
  getArdoqErrorTraceId,
  isArdoqError,
  Maybe,
} from '@ardoq/common-helpers';
import { logError } from '@ardoq/logging';
import {
  updatedInventoryColumns,
  componentFieldValueWasUpdated,
} from './actions';
import { InventoryColumnDefinitionWithOptionList } from './types';
import {
  APIEntityType,
  ArdoqId,
  QueryBuilderSubquery,
  isQueryBuilderSubquery,
  QueryBuilderRule,
  InventoryColumnState,
} from '@ardoq/api-types';
import {
  inventoryModuleWasSelected,
  navigateToAuditLog,
  navigateToReportBuilder,
} from 'router/navigationActions';
import { inventoryOperations } from './inventoryOperations';
import { componentOverviewDrawerCommands } from './componentOverviewDrawer/componentOverviewDrawerCommands';
import {
  componentSelectionChanged,
  componentWasCreated,
  filtersChanged,
} from './interactiveTopRow/actions';
import { launchDeleteComponentsAndReferencesConfirmationDialogByIds } from 'components/Dialogs/confirmDeletion/confirmDeletion';
import { trackAuditLogEntryPoint } from 'auditLog/tracking';
import { inventoryTracking } from './inventoryTracking';
import { isBoolean } from 'lodash';
import { debounce } from 'lodash';
import { DEBOUNCE_VALUE_FOR_TYPING_IN_INPUT, InventoryModule } from './consts';

const calculateNumberOfRulesInSubQuery = (
  query: QueryBuilderSubquery | QueryBuilderRule
): number => {
  if (isQueryBuilderSubquery(query)) {
    // If subQuery, we do recursion for each rule in the subQuery
    return query.rules.reduce(
      (acc, rule) => acc + calculateNumberOfRulesInSubQuery(rule),
      0
    );
  }
  return 1;
};

const debouncedTrackFilterTableContents = debounce(
  subquery =>
    inventoryTracking.trackFilterTableContents(
      calculateNumberOfRulesInSubQuery(subquery)
    ),
  DEBOUNCE_VALUE_FOR_TYPING_IN_INPUT
);

const updateComponentFieldValue: UpdateComponentFieldValue =
  async updatedComponentAttributes => {
    const response = await componentApi.updateComponent(
      updatedComponentAttributes
    );
    if (isArdoqError(response)) {
      logError(new Error(getArdoqErrorMessage(response)), null, {
        traceId: getArdoqErrorTraceId(response),
      });
      dispatchAction(
        componentFieldValueWasUpdated({
          error: response,
          updatePayload: updatedComponentAttributes,
        })
      );
    }
    return response;
  };

const createComponent = async (componentAttributes: CreateComponentParam) => {
  const response = await componentApi.createComponent(componentAttributes);
  if (isArdoqError(response)) {
    logError(new Error(getArdoqErrorMessage(response)), null, {
      traceId: getArdoqErrorTraceId(response),
    });
  }
  inventoryTracking.trackCreateComponent();
  return response;
};

const updateInventoryColumns = (columns: InventoryColumnState[]) => {
  dispatchAction(
    updatedInventoryColumns({
      columns,
    })
  );
};

type InventoryInputData = {
  allAvailableColumns: InventoryColumnDefinitionWithOptionList[];
  datasource: InventoryDatasourceSelection;
};

/**
 * As AG Grid is in charge of its own state, we need to provide async commands to interact with it so that
 * inventory knows when to react to changes in the grid.
 */
export const getInventoryCommands = (
  inventoryInputData: InventoryInputData,
  updatePayload: Maybe<UpdateComponentFieldValueParam>,
  inventoryGridApi: Maybe<InventoryGridApi>
): InventoryCommands => ({
  viewComponentsInAuditLog: (selectedComponents: SelectedComponent[]) => {
    trackAuditLogEntryPoint(`inventory`);
    dispatchAction(
      navigateToAuditLog({
        entities: selectedComponents.map(({ _id, name }) => ({
          id: _id,
          name,
        })),
        entityType: APIEntityType.COMPONENT,
      })
    );
    inventoryTracking.trackNavigateToAuditLogFromTable(
      selectedComponents.length
    );
  },
  deleteComponents: (componentIds: ArdoqId[]) => {
    inventoryTracking.trackDeleteComponents(componentIds.length);
    return componentApi.deleteComponents(componentIds);
  },
  launchDeleteComponentsAndReferencesConfirmation: (
    selectedComponents: SelectedComponent[]
  ) =>
    launchDeleteComponentsAndReferencesConfirmationDialogByIds({
      componentIds: selectedComponents.map(({ _id }) => _id),
      componentNames: selectedComponents.map(({ name }) => name),
      shouldReturnComponentBatchDeleteResponse: true,
    }).then(response => {
      if (
        response &&
        'componentIds' in response &&
        'referenceIds' in response
      ) {
        inventoryTracking.trackDeleteComponentsAndReferences(
          response.componentIds.length,
          response.referenceIds.length
        );
      }
      return response;
    }),
  registerInventoryGridApi: inventoryGridApi => {
    dispatchAction(registerInventoryGridApi(inventoryGridApi));
  },
  deregisterInventoryGridApi: () => {
    dispatchAction(deregisterInventoryGridApi());
  },
  notifyColumnMoved: ({ toIndex }) => {
    if (!inventoryGridApi) {
      return;
    }
    updateInventoryColumns(inventoryGridApi.getAllColumns());
    if (toIndex) {
      inventoryTracking.trackMoveColumnInTable(toIndex);
    }
  },
  notifyColumnVisible: ({ columns, visible }) => {
    if (!inventoryGridApi) {
      return;
    }
    updateInventoryColumns(inventoryGridApi.getAllColumns());
    if (columns && isBoolean(visible)) {
      inventoryTracking.trackSetColumnVisibilityInTable(
        columns.length,
        visible
      );
    }
  },
  notifyColumnPinned: ({ pinned, source }) => {
    if (!inventoryGridApi) {
      return;
    }
    updateInventoryColumns(inventoryGridApi.getAllColumns());
    // When a column is pinned, we get 'left' | 'right' strings.
    // When it is unpinned, we get null / undefined.
    if (typeof pinned === 'string') {
      inventoryTracking.trackPinColumnInTable(pinned, source);
    } else {
      inventoryTracking.trackUnPinColumnInTable(source);
    }
  },
  updateComponentFieldValue,
  saveAsReport: () => {
    if (!inventoryGridApi) {
      return;
    }

    const inventoryColumns = inventoryGridApi.getAllColumns();
    const inventoryColumnFiltersAsSubquery =
      inventoryGridApi.getFiltersAsSubquery();
    const inventoryAsReport =
      inventoryOperations.createAdHocSearchReportFromFilter(
        inventoryInputData.datasource,
        inventoryColumnFiltersAsSubquery,
        inventoryInputData.allAvailableColumns,
        inventoryColumns
      );

    dispatchAction(navigateToReportBuilder(inventoryAsReport));

    inventoryTracking.trackSaveAsReport(
      inventoryColumnFiltersAsSubquery.rules.length
    );
  },
  createComponent,
  retryComponentUpdate: async () => {
    if (!updatePayload) {
      return null;
    }

    const response = await updateComponentFieldValue(updatePayload);
    if (!isArdoqError(response)) {
      dispatchAction(componentFieldValueWasUpdated(null));
    }
    return response;
  },
  showComponentOverviewDrawer: componentOverviewDrawerCommands.openDrawer,
  setSelectedComponents: selectedComponents => {
    dispatchAction(componentSelectionChanged(selectedComponents));
  },
  notifyFilterChanged: subquery => {
    dispatchAction(filtersChanged(subquery));
    // Tracking requires a debounce because typing in the search input changes the filters.
    debouncedTrackFilterTableContents(subquery);
  },
  incrementTotalNumberOfComponents: () => dispatchAction(componentWasCreated()),
  navigateToDataOverview: () => {
    dispatchAction(
      inventoryModuleWasSelected({
        inventoryModule: InventoryModule.OVERVIEW,
        dataSourceType: 'none',
      })
    );
    inventoryTracking.trackNavigateToDataOverviewFromInventory();
  },
});
