import type { CanvasRenderingContext } from '../types';
import descendantCountBadgeFont from './descendantCountBadgeFont';

const DESCENDANT_COUNT_BADGE_TEXT_BASELINE = 'alphabetic';

const countBadgeTextMetricsForContextAndFontSize = new Map();
const getCountBadgeTextMetricsForContextAndFontSize = (
  context: CanvasRenderingContext,
  fontSize: number
) => {
  const existingTextMetrics =
    countBadgeTextMetricsForContextAndFontSize.get(fontSize);
  if (!existingTextMetrics) {
    const newTextMetrics = {
      oneDigitLabelMetrics: context.measureText('9'),
      spaceMetrics: context.measureText(' '),
      parenthesisMetrics: context.measureText(')'),
    };
    countBadgeTextMetricsForContextAndFontSize.set(fontSize, newTextMetrics);
    return newTextMetrics;
  }
  return existingTextMetrics;
};

const descendantCountBadgeTextAndIconMetrics = (
  disconnectedChildrenCount: number,
  fontSize: number,
  x: number,
  y: number,
  descendantCount: number,
  context: CanvasRenderingContext,
  disconnectedNodesHighlighted: boolean
) => {
  // #region text metrics

  /**
   * Font size does not necessarily gives us the height of the text, as textMetrics.width does not necessarily
   * gives the correct width. As we are currently working only with numbers, parentheses and spaces, we can
   * rely in the metrics.width, but should calculate the actual height of numbers if we want to center them
   * within the badge. Parentheses are top-aligned with numbers, so we exlude them from the height measurement.
   */

  const isDisconnectedChildrenCountShown =
    disconnectedChildrenCount && disconnectedNodesHighlighted;

  // This function can be called from anywhere. We should not mutate the context but need the font and
  // textBaseline for metrics. So we set it temporarily.
  const previousContextTextBaseline = context.textBaseline;
  const previousContextFont = context.font;

  context.textBaseline = DESCENDANT_COUNT_BADGE_TEXT_BASELINE;

  context.font = descendantCountBadgeFont(fontSize);
  const { oneDigitLabelMetrics, spaceMetrics, parenthesisMetrics } =
    getCountBadgeTextMetricsForContextAndFontSize(context, fontSize);

  // If numbers should be centered, use countLabelMetrics, if parentheses - parenthesisMetrics.
  // If we should switch the alignment based on presense of parentheses - concat the label and
  // measure the result.
  const actualTextHeight =
    oneDigitLabelMetrics.actualBoundingBoxAscent +
    oneDigitLabelMetrics.actualBoundingBoxDescent;

  // Measurments of label parts.
  const descendantCountLabelWidth =
    `${descendantCount}`.length * oneDigitLabelMetrics.width;
  const disconnectedChildrenCountLabelWidth =
    `${disconnectedChildrenCount}`.length * oneDigitLabelMetrics.width;
  const badgeLabeLBeforeIconWidth = isDisconnectedChildrenCountShown
    ? descendantCountLabelWidth +
      spaceMetrics.width +
      disconnectedChildrenCountLabelWidth +
      parenthesisMetrics.width
    : descendantCountLabelWidth;

  context.textBaseline = previousContextTextBaseline;
  context.font = previousContextFont;

  // #endregion

  // #region badge metrics

  // min padding is 4, max sould be scalable.
  const childCountBadgePadding = Math.max(actualTextHeight * 0.3, 4);

  // icon should be somewhat bigger than a one digit width.
  const iconDiameter = oneDigitLabelMetrics.width * 1.3;

  // badge width === total label width.
  const badgeWidth =
    childCountBadgePadding * 2 +
    (isDisconnectedChildrenCountShown
      ? badgeLabeLBeforeIconWidth + iconDiameter + parenthesisMetrics.width
      : descendantCountLabelWidth);

  const badgeX = x;
  const badgeY = y;
  const badgeHeight = actualTextHeight + childCountBadgePadding * 2;
  const badgeLeft = badgeX - badgeWidth / 2;
  const badgeRight = badgeX + badgeWidth / 2;
  const badgeBottom = badgeY + badgeHeight / 2;
  const badgeTop = badgeY - badgeHeight / 2;

  // #endregion

  // #region icon metrics

  const iconX =
    badgeX -
    badgeWidth / 2 +
    childCountBadgePadding +
    badgeLabeLBeforeIconWidth;
  const iconY = badgeTop + (badgeHeight - iconDiameter) / 2; // center the icon vertically

  // #endregion

  return {
    badgeMetrics: {
      badgeX,
      badgeY,
      badgeHeight,
      badgeWidth,
      badgeLeft,
      badgeRight,
      badgeBottom,
      badgeTop,
      childCountBadgePadding,
    },
    textMetrics: {
      badgeLabeLBeforeIconWidth,
    },
    iconMetrics: {
      iconX,
      iconY,
      iconR: iconDiameter / 2,
    },
    quantityOfDisconnectedChildren: disconnectedChildrenCount,
    isDisconnectedChildrenCountShown,
  };
};

export default descendantCountBadgeTextAndIconMetrics;
