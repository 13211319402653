export function extractErrorData(error: unknown): string | undefined {
  if (!error) return undefined;
  if (typeof error !== 'object') return undefined;
  if (!('data' in error)) return undefined;
  const errorData = error.data;

  if (!errorData) return undefined;

  if (typeof errorData === 'string') {
    return errorData.slice(0, 20000); // Limit to 20,000 characters
  }

  if (typeof errorData === 'object') {
    const jsonString = JSON.stringify(errorData);
    return jsonString.slice(0, 20000); // Limit to 20,000 characters
  }

  return String(errorData);
}
