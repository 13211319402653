import { useRef } from 'react';
import { TextInput } from '@ardoq/forms';
import { Stack, FlexBox, Box } from '@ardoq/layout';
import { Text } from '@ardoq/typography';
import { Select } from '@ardoq/select';
import { VisualComponentsMappingsProps } from './types';
import { ButtonSize, IconButton, SecondaryButton } from '@ardoq/button';
import { Icon, IconName } from '@ardoq/icons';
import { ASYNC_STATUS } from 'integrations/common/types/api';
import { colors } from '@ardoq/design-tokens';
import { pluralize } from '@ardoq/common-helpers';
import { EmptyMappings } from './EmptyMappings';
import { generateMappingId } from '../streams/mappings/utils';

export const VisualComponentsMappings = ({
  mappingsStatus,
  components,
  componentTypeOptions,
  onComponentMapping,
  onComponentTypeChange,
  onAddNewComponentMapping,
  onDeleteComponentMapping,
}: VisualComponentsMappingsProps) => {
  const focusedInputRef = useRef<string | null>(null);

  const isSuccess = mappingsStatus === ASYNC_STATUS.SUCCESS;

  return (
    <Stack gap="medium">
      <Box paddingLeft="medium">
        <Text variant="text2">
          Verify the component name and the component type from the diagram. Add
          new component if missing.
        </Text>
      </Box>
      <FlexBox
        justify="space-between"
        width="full"
        paddingLeft="medium"
        align="center"
      >
        <Box>
          <Text
            variant="text2Bold"
            color="textSubtle"
          >{`${components.length} ${pluralize('component', components.length)}`}</Text>
        </Box>
        <SecondaryButton
          onClick={() => {
            const newId = generateMappingId();
            onAddNewComponentMapping({
              id: newId,
              name: '',
              type: '',
            });
            focusedInputRef.current = newId;
          }}
          isDisabled={isSuccess}
        >
          <Icon iconName={IconName.ADD} />
          Add new component
        </SecondaryButton>
      </FlexBox>

      {components.length === 0 && <EmptyMappings entity="component" />}
      <FlexBox flexDirection="column" gap="small">
        {components.length > 0 && (
          <FlexBox gap="none" align="start">
            <FlexBox marginLeft="medium" flex={1}>
              <Text variant="text1Bold">Name</Text>
            </FlexBox>
            <FlexBox marginLeft="xlarge" flex={1}>
              <Text variant="text1Bold">Type</Text>
            </FlexBox>
          </FlexBox>
        )}
        {components.map(component => {
          // TODO: move the data prep to the viewModel
          const componentTypes = (
            component.componentTypeId
              ? componentTypeOptions
              : [
                  {
                    value: component.componentTypeId ?? component.type,
                    label: component.type,
                    isDisabled: true,
                  },
                  ...componentTypeOptions,
                ]
          ).filter(({ label }) => Boolean(label));

          return (
            <FlexBox
              key={`component-mapping-${component.id}`}
              gap="none"
              align="start"
            >
              <FlexBox flex={1} paddingX="medium" paddingY="xsmall">
                <Box width="full">
                  <TextInput
                    key={`component-input-${component.id}`}
                    onValueChange={name => {
                      onComponentMapping({
                        ...component,
                        name,
                      });
                    }}
                    onFocus={() => {
                      focusedInputRef.current = component.id;
                    }}
                    onBlur={() => {
                      focusedInputRef.current = null;
                    }}
                    autoFocus={focusedInputRef.current === component.id}
                    defaultValue={component.name}
                    warningMessage={
                      !component.name.trim()
                        ? 'Missing component name'
                        : undefined
                    }
                  />
                </Box>
              </FlexBox>
              <FlexBox
                flex={1}
                paddingLeft="medium"
                paddingY="xsmall"
                justify="center"
              >
                <Box width="full">
                  <Select
                    key={`component-type-select-${component.id}`}
                    onValueChange={componentTypeId => {
                      if (componentTypeId !== null) {
                        onComponentTypeChange(component, componentTypeId);
                      }
                    }}
                    warningMessage={
                      !component.componentTypeId
                        ? 'Missing component type'
                        : undefined
                    }
                    value={component.componentTypeId ?? component.type}
                    options={componentTypes}
                  />
                </Box>
                <Box>
                  {isSuccess ? (
                    <Box paddingLeft="xsmall" paddingTop="xsmall">
                      <Icon
                        iconName={IconName.CHECK_CIRCLE}
                        color={colors.surfaceSuccessStrong}
                      />
                    </Box>
                  ) : (
                    <IconButton
                      iconName={IconName.DELETE}
                      buttonSize={ButtonSize.SMALL}
                      onClick={() => onDeleteComponentMapping(component)}
                    />
                  )}
                </Box>
              </FlexBox>
            </FlexBox>
          );
        })}
      </FlexBox>
    </Stack>
  );
};
