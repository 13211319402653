import { ButtonGroup, PrimaryButton, SecondaryButton } from '@ardoq/button';
import { DoqType, DoqWithSideContent } from '@ardoq/doq';
import { testId } from 'integrations/common/testUtils/testId';
import { IslandBody, IslandContainer, IslandFooter } from '@ardoq/page-layout';

type TransferErrorProps = {
  handleMessageSupport: VoidFunction;
  handleOpenReport: VoidFunction;
  transferDirection: 'import' | 'export';
  errorMessage?: string;
};

export const TransferError = ({
  handleMessageSupport,
  handleOpenReport,
  transferDirection,
  errorMessage = `Message support and we'll get this fixed for you or view the ${transferDirection} report to check for issues.`,
}: TransferErrorProps) => {
  return (
    <>
      <IslandContainer $maxWidth="100%" {...testId('transfer-error')}>
        <IslandBody>
          <DoqWithSideContent
            title="There was a technical issue"
            message={errorMessage}
            doqType={DoqType.ERROR}
          ></DoqWithSideContent>
        </IslandBody>
        <IslandFooter $justify="end">
          <ButtonGroup>
            <SecondaryButton onClick={handleOpenReport}>
              {`View ${transferDirection} report`}
            </SecondaryButton>
            <PrimaryButton onClick={handleMessageSupport}>
              Message support
            </PrimaryButton>
          </ButtonGroup>
        </IslandFooter>
      </IslandContainer>
    </>
  );
};
