import { trackEvent } from 'tracking/tracking';
import { DataOverviewTypes } from './dataOverview/types';
import { ViewIds } from '@ardoq/api-types';

const trackOpenInventoryFromSidebar = () => {
  trackEvent('Inventory0 Opened from sidebar');
};

const trackFocusOnSearchInputInDataOverview = (
  dataOverviewType: DataOverviewTypes
) => {
  trackEvent('Inventory0 Focused on search input in Data Overview', {
    dataOverviewType,
  });
};

const trackClearInSearchInputInDataOverview = () => {
  trackEvent('Inventory0 Cleared search input in Data Overview');
};

const trackSelectTypeInDataOverview = (dataOverviewType: DataOverviewTypes) => {
  trackEvent('Inventory0 Selected type in Data Overview', {
    dataOverviewType,
  });
};

const trackOpenItemsInInventory = ({
  dataOverviewType,
  numberOfItems,
}: {
  dataOverviewType: DataOverviewTypes;
  numberOfItems: number;
}) => {
  trackEvent('Inventory0 Opened items in Inventory', {
    dataOverviewType,
    numberOfItems,
  });
};

const trackClickOnItemNameInDataOverview = (
  dataOverviewType: DataOverviewTypes
) => {
  trackEvent('Inventory0 Clicked on item name in Data Overview', {
    dataOverviewType,
  });
};

const trackToggleCheckboxForItemInDataOverview = ({
  dataOverviewType,
  value,
}: {
  dataOverviewType: DataOverviewTypes;
  value: boolean;
}) => {
  trackEvent('Inventory0 Toggled checkbox for item in Data Overview', {
    dataOverviewType,
    value,
  });
};

const trackClickOnOpenButtonInDataOverview = ({
  dataOverviewType,
  numberOfItems,
}: {
  dataOverviewType: DataOverviewTypes;
  numberOfItems: number;
}) => {
  trackEvent('Inventory0 Clicked on Open button in Data Overview', {
    dataOverviewType,
    numberOfItems,
  });
};

const trackOpenColumnSelectionPanelFromTopRow = () => {
  trackEvent('Inventory0 Opened column selection panel from Top Row');
};

const trackEnterCreateComponentsModeFromTopRow = () => {
  trackEvent('Inventory0 Entered create components mode from Top Row');
};

const trackExitCreateComponentsModeFromTopRow = () => {
  trackEvent('Inventory0 Exited create components mode from Top Row');
};

const trackAddNewRowFromTopRow = () => {
  trackEvent('Inventory0 Added new row from Top Row');
};

const trackDeselectAllRowsFromTopRow = () => {
  trackEvent('Inventory0 Deselected all rows from Top Row');
};

const trackDeleteSelectedRowsFromTopRow = (numberOfComponents: number) => {
  trackEvent('Inventory0 Deleted selected components from Top Row', {
    numberOfComponents,
  });
};

const trackNavigateToAuditLogFromTopRow = (numberOfComponents: number) => {
  trackEvent('Inventory0 Navigated to Audit Log from Top Row', {
    numberOfComponents,
  });
};

const trackOpenSelectedComponentsInVisualization = (
  numberOfComponents: number,
  visualization: ViewIds
) => {
  trackEvent(
    'Inventory0 Opened selected components in visualization from Top Row',
    {
      visualization,
      numberOfComponents,
    }
  );
};

const trackOpenApplySavedViewpointModal = (numberOfComponents: number) => {
  trackEvent('Inventory0 Opened apply saved viewpoint modal from Top Row', {
    numberOfComponents,
  });
};

const trackSaveAsReport = (numberOfFilters: number) => {
  trackEvent('Inventory0 Clicked save as report', {
    numberOfFilters,
  });
};

const trackOpenComponentDrawer = () => {
  trackEvent('Inventory0 Opened Component Drawer');
};

const trackCloseComponentDrawer = () => {
  trackEvent('Inventory0 Closed Component Drawer');
};

const trackSelectComponentFromComponentDrawer = () => {
  trackEvent('Inventory0 Selected component from Component Drawer');
};

const trackOpenViewpointFromComponentDrawer = () => {
  trackEvent('Inventory0 Opened viewpoint from Component Drawer');
};

const trackDeleteComponents = (numberOfComponents: number) => {
  trackEvent('Inventory0 Deleted components', {
    numberOfComponents,
  });
};

const trackDeleteComponentsAndReferences = (
  numberOfComponents: number,
  numberOfReferences: number
) => {
  trackEvent('Inventory0 Deleted components and references', {
    numberOfComponents,
    numberOfReferences,
  });
};

const trackNavigateToAuditLogFromTable = (numberOfComponents: number) => {
  trackEvent('Inventory0 Navigated to Audit Log from Table', {
    numberOfComponents,
  });
};

const trackSetColumnVisibilityInTable = (
  numberOfColumns: number,
  isVisible: boolean
) => {
  trackEvent('Inventory0 Set column visibility in table', {
    numberOfColumns,
    isVisible,
  });
};

const trackPinColumnInTable = (position: string, triggeredFrom: string) => {
  trackEvent('Inventory0 Pinned column in table', {
    position,
    triggeredFrom,
  });
};

const trackUnPinColumnInTable = (triggeredFrom: string) => {
  trackEvent('Inventory0 Unpinned column in table', {
    triggeredFrom,
  });
};

const trackMoveColumnInTable = (toIndex: number) => {
  trackEvent('Inventory0 Moved column in table', {
    toIndex,
  });
};

const trackFilterTableContents = (numberOfRules: number) => {
  trackEvent('Inventory0 Filtered contents in Table', {
    numberOfRules,
  });
};

const trackNavigateToDataOverviewFromInventory = () => {
  trackEvent('Inventory0 Navigated to Data Overview from Inventory');
};

const trackCreateComponent = () => {
  trackEvent('Inventory0 Created new component');
};

export const inventoryTracking = {
  trackOpenInventoryFromSidebar,
  trackFocusOnSearchInputInDataOverview,
  trackClearInSearchInputInDataOverview,
  trackSelectTypeInDataOverview,
  trackOpenItemsInInventory,
  trackClickOnItemNameInDataOverview,
  trackToggleCheckboxForItemInDataOverview,
  trackClickOnOpenButtonInDataOverview,
  trackOpenColumnSelectionPanelFromTopRow,
  trackEnterCreateComponentsModeFromTopRow,
  trackExitCreateComponentsModeFromTopRow,
  trackAddNewRowFromTopRow,
  trackDeselectAllRowsFromTopRow,
  trackDeleteSelectedRowsFromTopRow,
  trackNavigateToAuditLogFromTopRow,
  trackOpenSelectedComponentsInVisualization,
  trackOpenApplySavedViewpointModal,
  trackSaveAsReport,
  trackOpenComponentDrawer,
  trackCloseComponentDrawer,
  trackSelectComponentFromComponentDrawer,
  trackOpenViewpointFromComponentDrawer,
  trackDeleteComponents,
  trackDeleteComponentsAndReferences,
  trackNavigateToAuditLogFromTable,
  trackSetColumnVisibilityInTable,
  trackPinColumnInTable,
  trackUnPinColumnInTable,
  trackMoveColumnInTable,
  trackFilterTableContents,
  trackNavigateToDataOverviewFromInventory,
  trackCreateComponent,
};
