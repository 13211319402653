import { createFifoCache } from '@ardoq/common-helpers';
import { colors } from '@ardoq/design-tokens';
import { ArdoqIconCategory, IconName } from '@ardoq/icons';
import { ARDOQ_DEFAULT_FONT_FAMILY } from '@ardoq/typography';
import { ZoomTransform } from 'd3';
import { darken } from 'polished';
import {
  FALLBACK_IMAGE,
  canvasResolvedImages,
  svgImage,
} from 'tabview/canvasRendering/canvasResolvedImages';
import type { Point } from '@ardoq/math';
import { getComponentCssColors } from 'utils/modelCssManager/getCssColors';
import {
  EXPANDED_GROUP_TEXT_NOMINALSIZE,
  EXPANDER_RADIUS,
  SUBLABEL_NOMINALSIZE,
  TEXT_NOMINALSIZE,
} from '../consts';
import { RelationshipsNode } from '../types';
import { getImageColorFilterValueForModel } from 'views/ConditionalFormattingImageColorFilters';
import canvasFontAwesomeIcon from 'tabview/canvasRendering/canvasFontAwesomeIcon';
import nodeLabelY from './nodeLabelY';
import { AREOLA_GAP } from './consts';
import { shouldShowExpander, markFatness, badgeLocation } from './util';
import drawDescendantCountBadge from 'tabview/canvasRendering/descendantCountBadge/drawDescendantCountBadge';
import groupDisconnectedChildren from '../viewModel$/groupDisconnectedChildren';

export const absolutePosition = (node: RelationshipsNode): Point => {
  const { x, y } = node;
  if (node.parent) {
    const [parentX, parentY] = absolutePosition(node.parent);
    return [x + parentX, y + parentY];
  }
  return [x, y];
};
const DESCENDANT_COUNT_BADGE_FONT_SIZE = 12;

const DEFAULT_COLORS = Object.freeze({
  fill: colors.grey95,
  stroke: colors.grey50,
});

const nodeColors = (
  { isSynthetic, modelId }: RelationshipsNode,
  useAsBackgroundStyle: boolean
) =>
  isSynthetic || !modelId
    ? DEFAULT_COLORS
    : (getComponentCssColors(modelId, { useAsBackgroundStyle }) ??
      DEFAULT_COLORS);

const getComponentColors = createFifoCache(
  Infinity,
  (node: RelationshipsNode) => nodeColors(node, true)
);

const resolveComponentColors = (
  node: RelationshipsNode,
  isHighlighted: boolean
) => {
  const { fill, stroke } = getComponentColors(node);
  const resolvedFill = fill ?? DEFAULT_COLORS.fill;
  const actualFill = isHighlighted
    ? highlightGroupFill(resolvedFill)
    : resolvedFill;
  return {
    strokeStyle: stroke ?? DEFAULT_COLORS.stroke,
    fillStyle: actualFill,
  };
};

const getIconColor = createFifoCache(Infinity, (node: RelationshipsNode) => {
  const nodeColor = nodeColors(node, false).fill ?? colors.black;
  return nodeColor === DEFAULT_COLORS.fill ? colors.black : nodeColor;
});

const addArcs = (
  context: CanvasRenderingContext2D,
  nodeX: number,
  nodeY: number,
  nodeRadius: number,
  expanderRadius: number,
  viewTransform: ZoomTransform
) => {
  const areolaRadius = expanderRadius + AREOLA_GAP * viewTransform.k;
  const upperAngle = 2 * Math.asin(areolaRadius / (2 * nodeRadius));
  const lowerAngle = (Math.PI - upperAngle) / 2;

  context.arc(
    nodeX,
    nodeY,
    nodeRadius,
    Math.PI / 2 + upperAngle,
    Math.PI / 2 - upperAngle,
    false
  ); // upper arc
  context.arc(
    nodeX,
    nodeY + nodeRadius,
    areolaRadius,
    -Math.PI / 2 + lowerAngle,
    -Math.PI / 2 - lowerAngle,
    false
  ); // lower arc
};

const adjustFontSize = (
  nominalSize: number,
  viewTransform: ZoomTransform,
  scale: number
) => nominalSize * viewTransform.k * devicePixelRatio * Math.min(scale, 1);

export const getNodeFontSize = (
  node: RelationshipsNode,
  viewTransform: ZoomTransform,
  scale: number
) => adjustFontSize(getNominalFontSize(node), viewTransform, scale);

const getNominalFontSize = ({ descendantCount, open }: RelationshipsNode) =>
  descendantCount && open ? EXPANDED_GROUP_TEXT_NOMINALSIZE : TEXT_NOMINALSIZE;

export const getSubLabelHeight = (
  viewTransform: ZoomTransform,
  scale: number
) => adjustFontSize(SUBLABEL_NOMINALSIZE, viewTransform, scale);

const drawImage = (
  context: CanvasRenderingContext2D,
  img: HTMLImageElement,
  x: number,
  y: number,
  width: number,
  height: number,
  filterStylePropertyValue: string
) => {
  if (filterStylePropertyValue) {
    context.filter = filterStylePropertyValue;
  }
  context.drawImage(img, x - width / 2, y - height / 2, width, height);
  if (filterStylePropertyValue) {
    context.filter = 'none';
  }
};

const drawNodeRepresentation = (
  node: RelationshipsNode,
  context: CanvasRenderingContext2D,
  iconRadius: number,
  x: number,
  y: number,
  clipRadius: number
) => {
  if (!node.representationData) {
    return;
  }
  const { isImage, value, icon } = node.representationData;
  if (isImage && value) {
    const img = canvasResolvedImages.get(value)!;
    node.isBrokenImage = img === FALLBACK_IMAGE;
    const aspectRatio = img?.complete
      ? img.naturalWidth / img.naturalHeight
      : NaN;
    const fullSize = node.isBrokenImage ? iconRadius : 2 * iconRadius;
    const { width, height } = isNaN(aspectRatio)
      ? { width: fullSize, height: 2 * fullSize }
      : aspectRatio > 1
        ? { width: fullSize, height: fullSize / aspectRatio }
        : { width: fullSize * aspectRatio, height: fullSize };
    context.save();
    if (isFinite(clipRadius)) {
      const clipPath = new Path2D();
      clipPath.ellipse(x, y, clipRadius, clipRadius, 0, 0, 2 * Math.PI);
      context.clip(clipPath);
    }

    if (img?.complete) {
      /**
       * If conditional formatting is applied, images should be colored accordingly. It does not help to
       * provide the filter url as a css property. Filter effects are not part of images, and only bitmap
       * is drawn. Therefore we temporarily set the `filter` property on the context itself.
       */
      const filterStylePropertyValue = getImageColorFilterValueForModel(
        node.modelId
      );
      drawImage(context, img, x, y, width, height, filterStylePropertyValue);
    }
    context.restore();
  } else if (icon) {
    const iconColor = getIconColor(node);
    if (icon.category === ArdoqIconCategory.FontAwesome) {
      canvasFontAwesomeIcon(context, x, y, icon.id, iconRadius, iconColor);
    } else if (icon.isSVG) {
      context.drawImage(
        // args interpolation: ${iconId}~~${color}~~${fill}~~${stroke}~~${width}~~${height}
        svgImage(`${icon.id}~~${iconColor}`),
        x - iconRadius / 2,
        y - iconRadius / 2,
        iconRadius,
        iconRadius
      );
    }
  }
};

const drawExpanderButton = (
  x: number,
  y: number,
  r: number,
  open: boolean,
  expanderRadius: number,
  context: CanvasRenderingContext2D
) => {
  context.beginPath();
  context.fillStyle = colors.white;
  context.strokeStyle = colors.grey80;
  context.lineWidth = 2;
  context.ellipse(x, y + r, expanderRadius, expanderRadius, 0, 0, 2 * Math.PI);
  context.fill();
  context.stroke();

  context.font = `${expanderRadius * 1.5}px Material Icons Round`;
  context.fillStyle = colors.grey50;
  context.fillText(
    open ? IconName.UNFOLD_LESS : IconName.UNFOLD_MORE,
    x,
    y + r
  );
};

export const getChildCountBadgeFontSizeForNode = (
  scaledNodeR: number,
  viewTransform: ZoomTransform,
  scale: number
) =>
  Math.round(
    Math.max(
      scaledNodeR / 5,
      DESCENDANT_COUNT_BADGE_FONT_SIZE *
        viewTransform.k *
        devicePixelRatio *
        Math.min(scale, 1)
    )
  );

export const isChildCountBadgeToBeDisplayed = (
  childCountBadgeFontSize: number
) => childCountBadgeFontSize / devicePixelRatio > 6;

const highlightGroupFill = createFifoCache(Infinity, darken(0.03));

/** @returns true if the font size is big enough to read. */
export const shouldShowNodeLabel = (fontSize: number) =>
  fontSize / devicePixelRatio > 6;

const drawNodeHighlight = (
  context: CanvasRenderingContext2D,
  x: number,
  y: number,
  r: number,
  gap: number
) => {
  context.beginPath();
  context.ellipse(x, y, r + gap, r + gap, 0, 0, 2 * Math.PI);
  context.stroke();
};
const shouldShowIcon = ({
  representationData,
  descendantCount,
  open,
  r,
}: RelationshipsNode) =>
  representationData && (!descendantCount || !open) && r / devicePixelRatio > 1;

export const drawNode = (
  node: RelationshipsNode,
  label: string,
  subLabel: string | null,
  viewTransform: ZoomTransform,
  context: CanvasRenderingContext2D,
  windowSize: [width: number, height: number],
  scale: number,
  marked: boolean,
  highlighted: boolean,
  disconnectedNodesHighlighted: boolean
) => {
  const {
    x: nodeX,
    y: nodeY,
    r: nodeR,
    isSynthetic,
    open,
    descendantCount,
    isContext,
  } = node;
  if (isSynthetic) {
    return;
  }
  const [x, y] = viewTransform.apply([nodeX, nodeY]);
  const r = nodeR * viewTransform.k;
  if (
    x + r < 0 ||
    y + r < 0 ||
    x - r > windowSize[0] ||
    y - r > windowSize[1]
  ) {
    // node is off-screen, we don't need to draw it
    return;
  }

  context.beginPath();
  const { fillStyle, strokeStyle } = resolveComponentColors(node, highlighted);
  context.strokeStyle = strokeStyle;
  context.fillStyle = fillStyle;

  const fontSize = getNodeFontSize(node, viewTransform, scale);
  context.textAlign = 'center';
  context.textBaseline = 'middle';
  const showExpander = shouldShowExpander(node, viewTransform.k);
  const expanderRadius = showExpander ? EXPANDER_RADIUS * viewTransform.k : 0;
  const fatness = isContext || marked ? markFatness(r) : 1;
  const markGap = fatness * 1.5;
  if (showExpander) {
    context.lineWidth = fatness;
    addArcs(context, x, y, r, expanderRadius, viewTransform);
    context.fill();
    context.strokeStyle = isContext ? colors.blue60 : context.strokeStyle;
    context.stroke();
  } else {
    context.lineWidth = 1;
    context.ellipse(x, y, r, r, 0, 0, 2 * Math.PI); // main circle
    context.fill();
    context.stroke();

    // #region context/highlight mark
    context.lineWidth = fatness;

    if (isContext) {
      context.lineWidth = fatness;
      context.strokeStyle = colors.blue60;
      drawNodeHighlight(context, x, y, r, markGap);
    } else if (marked) {
      drawNodeHighlight(context, x, y, r, markGap);
    }
    // #endregion
  }

  const showIcon = shouldShowIcon(node);

  // #region closed group icon
  if (!open && showIcon && descendantCount) {
    context.lineWidth = 1;
    context.beginPath();
    context.ellipse(x, y, r / 2, r / 2, 0, 0, 2 * Math.PI);
    context.fill();
    context.strokeStyle = strokeStyle;
    context.stroke();
  }
  // #endregion
  if (showExpander) {
    drawExpanderButton(x, y, r, open, expanderRadius, context);
  }

  // #region node label
  if (shouldShowNodeLabel(fontSize)) {
    const nodeLabelFont = `${fontSize}px ${ARDOQ_DEFAULT_FONT_FAMILY}`;
    context.font = nodeLabelFont;
    const showSubLabel = subLabel && node.open;
    const subLabelHeight = showSubLabel
      ? getSubLabelHeight(viewTransform, scale)
      : 0;

    const labelY =
      viewTransform.applyY(
        nodeLabelY(node, showExpander, marked || isContext)
      ) +
      fontSize / 2; // add half the line height because the label will be middle-aligned.
    const subLabelY = labelY + fontSize;

    const isOpenGroup = showExpander && open;
    const labelWidth = isOpenGroup ? context.measureText(label).width : 0; // no need to measure width when not showing icon
    const labelX = isOpenGroup ? x + fontSize : x;

    if (isOpenGroup) {
      drawNodeRepresentation(
        node,
        context,
        node.representationData?.isImage ? fontSize / 2 : fontSize,
        labelX - labelWidth / 2 - fontSize * 0.75,
        labelY,
        Infinity
      );
      context.font = nodeLabelFont; // drawNodeRepresentation will have changed the font if the icon is from FontAwesome. we must set it back before we draw the node label.
    }
    context.fillStyle = colors.black;
    context.fillText(label, labelX, labelY);
    if (showSubLabel) {
      context.font = `italic ${subLabelHeight}px ${ARDOQ_DEFAULT_FONT_FAMILY}`;
      context.fillText(subLabel, x, subLabelY);
    }
  }
  // #endregion

  if (!open && descendantCount) {
    const descendantCountBadgeFontSize = getChildCountBadgeFontSizeForNode(
      r,
      viewTransform,
      scale
    );
    if (isChildCountBadgeToBeDisplayed(descendantCountBadgeFontSize)) {
      const disconnectedChildrenCount = groupDisconnectedChildren(
        [],
        node
      ).length;
      const [badgeX, badgeY] = badgeLocation(x, y, r);
      drawDescendantCountBadge({
        fontSize: descendantCountBadgeFontSize,
        x: badgeX,
        y: badgeY,
        descendantCount,
        context,
        disconnectedNodesHighlighted,
        disconnectedChildrenCount,
      });
    }
  }

  if (!showIcon) {
    return;
  }

  const iconRadius = descendantCount ? r / 2 : r;

  drawNodeRepresentation(
    node,
    context,
    iconRadius,
    x,
    y,
    showExpander && !open ? r / 2 : r
  );
};
