import { getTransferStateStream } from 'integrations/common/streams/transferState/getTransferStateStream';
import { EMPTY, of, switchMap } from 'rxjs';
import { TransferError } from 'integrations/common/components/transfer/transferError/TransferError';
import { dispatchAction, connect } from '@ardoq/rxbeach';
import { showImportErrorModal, showSupport } from 'integrations/actions';
import {
  TransferDirection,
  TransferState,
} from 'integrations/common/streams/transferState/types';
import { integrationId$ } from 'integrations/common/streams/integrationId/integrationId$';
import { excelDictionary } from 'integrations/common/dictionary';

const handleMessageSupport = () => {
  dispatchAction(
    showSupport({
      message: `Help! There was a technical issue with my ${excelDictionary.name} import`,
    })
  );
};

const handleOpenReport = () => {
  dispatchAction(showImportErrorModal());
};

const viewModel$ = integrationId$.pipe(
  switchMap(integrationId => getTransferStateStream(integrationId)),
  switchMap((transferState: TransferState) =>
    transferState.requestStatus !== 'FAILURE'
      ? EMPTY
      : of({
          handleOpenReport,
          handleMessageSupport,
          transferDirection: transferState.transferDirection,
          errorMessage: transferState.errorMessage,
        })
  )
);

const ErrorComponent = ({
  handleOpenReport,
  handleMessageSupport,
  transferDirection,
  errorMessage,
}: {
  handleOpenReport: () => void;
  handleMessageSupport: () => void;
  transferDirection: TransferDirection;
  errorMessage?: string;
}) => {
  return (
    <TransferError
      handleOpenReport={handleOpenReport}
      handleMessageSupport={handleMessageSupport}
      transferDirection={transferDirection}
      errorMessage={errorMessage}
    />
  );
};

export const Error = connect(ErrorComponent, viewModel$);
