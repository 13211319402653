import { TransferRequestPayload, TransferResponse } from './types';
import { IntegrationId } from '../tabularMappings/types';
import { getAsyncRequestId } from 'integrations/common/async/utils';
import { AsyncOperations } from 'integrations/common/async/constants';

const API_URL = '/api/integrations/tabular/source';

export class ImportDryRunError extends Error {
  constructor(
    public status: 'ImportDryRunError' | 'Error',
    public message: string,
    public data?: unknown
  ) {
    super();
  }
}

export const importRun = async ({
  sourceId,
  config,
  dryRun,
  async = false,
  integrationId,
  funnelId,
}: {
  sourceId: string;
  config: TransferRequestPayload;
  dryRun: boolean;
  async?: boolean;
  integrationId: IntegrationId;
  funnelId: string;
}) => {
  const requestId = getAsyncRequestId({
    integrationId,
    funnelId,
    operation: dryRun ? AsyncOperations.IMPORT_DRY_RUN : AsyncOperations.IMPORT,
  });
  const url = `${API_URL}/${sourceId}/import?dryRun=${dryRun}&async=${async}&requestId=${requestId}`;

  try {
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(config),
    });
    if (response.status >= 400 && response.status < 500) {
      const errorResponse = await response.json();
      const errorMessage = errorResponse.message || 'Unknown error';
      const errorData = errorResponse.data;
      throw new ImportDryRunError('ImportDryRunError', errorMessage, errorData);
    }
    if (response.status !== 201) {
      throw new ImportDryRunError('ImportDryRunError', response.statusText);
    }

    const formatted: TransferResponse = await response.json();
    return { ...formatted, async };
  } catch (error: unknown) {
    if (error instanceof Error) {
      throw error;
    }

    throw new Error(String(error));
  }
};
