import { Icon, IconName } from '@ardoq/icons';
import {
  PanelContainer,
  PanelFooter,
  PanelHeader,
  SectionsContainer,
} from 'appLayout/ardoqStudio/saveAsViewpoint/atoms';
import { GhostButton, UnstyledButton } from '@ardoq/button';
import { connect, dispatchAction } from '@ardoq/rxbeach';
import { hideViewSidePanel } from 'views/mainApp/viewSidePanel/actions';
import { Box, FlexBox, Stack } from '@ardoq/layout';
import {
  ViewpointSwitcherViewModel,
  viewpointSwitcherViewModel$,
} from './viewpointSwitcherViewModel$';
import { pluralizeWithCount } from '@ardoq/common-helpers';
import { Header5, Paragraph } from '@ardoq/typography';
import styled from 'styled-components';
import { colors, radius } from '@ardoq/design-tokens';
import { TextOverflow } from '@ardoq/popovers';
import { contextToolBarCommands } from '../contextToolBarCommands';
import ViewpointThumbnail from './ViewpointThumbnail';
import { viewIdToUserFriendlyName } from '../../views/metaInfoTabs';
import { StatusType, Tag } from '@ardoq/status-ui';
import { ComponentDecoratorAsIcon } from '@ardoq/decorators';

const ViewpointButton = styled(UnstyledButton)<{ $isCurrent: boolean }>`
  border: 1px solid
    ${({ $isCurrent }) => ($isCurrent ? colors.green60 : colors.borderSubtle00)};
  overflow: hidden;
  width: 100%;
  cursor: pointer;
  border-radius: ${radius.medium};
  position: relative;

  &:hover {
    border: 1px solid ${colors.borderActionHover};
  }
`;

const IsCurrentTag = styled.div`
  position: absolute;
  top: 0.5rem;
  left: 0.5rem;
`;
const ViewpointSwitcher = ({
  viewpoints,
  currentContext,
}: ViewpointSwitcherViewModel) => {
  return (
    <PanelContainer>
      <PanelHeader>
        <Icon iconName={IconName.VISIBILITY} />
        <span>Select viewpoint</span>
      </PanelHeader>
      <SectionsContainer>
        <FlexBox justify="space-between" paddingY="small" paddingX="medium">
          <FlexBox gap="small" align="center">
            {currentContext && (
              <ComponentDecoratorAsIcon
                {...currentContext.representationData!}
              />
            )}
            <TextOverflow>
              {currentContext?.label ?? 'No selection'}
            </TextOverflow>
          </FlexBox>
          <Box padding="small">
            {pluralizeWithCount('viewpoint', viewpoints.length)}
          </Box>
        </FlexBox>
        <Box padding="small">
          <Stack gap="small">
            {viewpoints.map(({ attributes, preview, isCurrent }) => (
              <FlexBox key={attributes._id} gap="small">
                <ViewpointButton
                  $isCurrent={isCurrent}
                  role="button"
                  onClick={() => {
                    contextToolBarCommands.setNewViewpoint(
                      attributes,
                      currentContext!.selectedIds
                    );
                  }}
                >
                  <Stack gap="small">
                    <ViewpointThumbnail {...preview} />
                    {isCurrent && (
                      <IsCurrentTag>
                        <Tag
                          statusType={StatusType.SUCCESS}
                          label="Current"
                          leftIcon={{ name: IconName.CHECK_CIRCLE }}
                        />
                      </IsCurrentTag>
                    )}
                    <Box padding="small">
                      <Stack gap="small" align="flex-start">
                        <TextOverflow lineClamp={2}>
                          <Header5 align="left">{attributes.name}</Header5>
                        </TextOverflow>
                        <Paragraph>
                          {viewIdToUserFriendlyName[attributes.viewName]}
                        </Paragraph>
                        <TextOverflow lineClamp={2}>
                          <Paragraph align="left">
                            {attributes.description}
                          </Paragraph>
                        </TextOverflow>
                      </Stack>
                    </Box>
                  </Stack>
                </ViewpointButton>
              </FlexBox>
            ))}
          </Stack>
        </Box>
      </SectionsContainer>
      <PanelFooter $padding="s16" $alignToRight>
        <Stack align="flex-end">
          <FlexBox gap="medium" align="flex-end">
            <GhostButton
              onClick={() => {
                dispatchAction(hideViewSidePanel());
              }}
            >
              Cancel
            </GhostButton>
          </FlexBox>
        </Stack>
      </PanelFooter>
    </PanelContainer>
  );
};
export default connect(ViewpointSwitcher, viewpointSwitcherViewModel$);
