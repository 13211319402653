import { action$, reducer, reduceState, streamReducer } from '@ardoq/rxbeach';
import { map } from 'rxjs/operators';
import {
  BaseAppLayoutState,
  GridEditorOpenState,
  MainAppLayoutProps,
  ResizeDirection,
} from './types';
import {
  getContentBarStyle,
  getEditorPaneStyle,
  getHorizontalResizeStyle,
  getLeftPaneStyle,
  getPaneLeft,
  getPanelTop,
  getRightEditorPaneStyle,
  getRightPaneStyle,
  getVerticalResizeStyle,
} from './utils';
import { gridEditorOpenState$ } from './gridEditorOpenState$';
import {
  CONTENT_BAR_HEIGHT,
  DEFAULT_PANEL_HEIGHT,
  DEFAULT_RIGHT_PANE_WIDTH,
} from './consts';
import {
  type ResizeUpdate,
  resizeCancel,
  resizeEnd,
  resizeStart,
  resizeUpdate,
} from './actions';
import { clamp } from 'lodash';
import { isSplitPane$ } from './isSplitPane$';
import { isRightEditorPaneOpen$ } from './isRightEditorPaneOpen$';
import { isDetailsDrawerOpen$ } from './ardoqStudio/detailsDrawer/isDetailsDrawerOpen$';
import { isViewpointMode$ } from 'traversals/loadedGraph$';
import { ExtractStreamShape } from 'tabview/types';
import { spreadPayloadOverState } from '@ardoq/common-helpers';
import { Features, hasFeature } from '@ardoq/features';

/*

  The purpose is to layout all main components in the same container without
  any markup nesting. It's by purpose not generic, but specific to the according
  view.

  The main components are:
    - Visualizations panes with tab selector (can be split to two views)
    - Sidebar editor
    - Grid editor

  The main rules are:
    - Opening the grid editor closes the sidebar editor.
    - Opening the grid editor keeps a split view
    - Opening the sidebar editor enforces a single visualization and closes
      the grid editor.
    - Closing the sidebar editor restores the split view
    - In a split view either of the two views can be closed.

  The rules for styles (mostly relevant when resizing the window):
    - Slit view widths are in percentages.
    - Editor width or height are in pixels.

*/

const contextReducer = streamReducer<BaseAppLayoutState, GridEditorOpenState>(
  gridEditorOpenState$,
  spreadPayloadOverState
);

const handleResizeStart = (
  state: BaseAppLayoutState,
  { direction, x, y, box }: ResizeUpdate
) => {
  const rightEditorPaneWidth = Math.min(state.rightEditorPaneWidth, box.width);
  const leftPaneWidth = state.isRightEditorPaneOpen
    ? 1 - rightEditorPaneWidth / box.width
    : state.leftPaneWidth;
  const panelTop =
    getPanelTop(state.editorHeight, box) -
    (state.isViewpointMode ? 0 : CONTENT_BAR_HEIGHT);
  const paneLeft = getPaneLeft(leftPaneWidth, box);
  const isVerticalResize = direction === ResizeDirection.VERTICAL;
  const resizeStartDelta = isVerticalResize ? panelTop - y : paneLeft - x;
  const resizeStartPosition = isVerticalResize ? y : x;
  const minResizeDelta = isVerticalResize
    ? -(panelTop - box.top)
    : -(paneLeft - box.left);
  const maxResizeDelta = isVerticalResize
    ? box.bottom - CONTENT_BAR_HEIGHT - panelTop
    : box.right - paneLeft;

  return {
    ...state,
    rightEditorPaneWidth,
    resizeDirection: direction,
    resizeStartDelta,
    resizeStart: resizeStartPosition,
    minResizeDelta,
    maxResizeDelta,
  };
};
const resizeStartReducer = reducer<BaseAppLayoutState, ResizeUpdate>(
  resizeStart,
  handleResizeStart
);

const handleResizeUpdate = (
  state: BaseAppLayoutState,
  { direction, x, y }: ResizeUpdate
) => {
  if (state.resizeDirection === null) {
    return state;
  }

  const resizeDelta = clamp(
    (direction === ResizeDirection.VERTICAL ? y : x) -
      (state.resizeStart + state.resizeStartDelta),
    state.minResizeDelta,
    state.maxResizeDelta
  );

  return {
    ...state,
    resizeDelta,
  };
};
const resizeUpdateReducer = reducer<BaseAppLayoutState, ResizeUpdate>(
  resizeUpdate,
  handleResizeUpdate
);

const handleResizeEnd = (
  state: BaseAppLayoutState,
  { direction, box }: ResizeUpdate
) => {
  if (state.resizeDirection === null) {
    return state;
  }

  return {
    ...state,
    editorHeight:
      direction === ResizeDirection.VERTICAL
        ? state.editorHeight - state.resizeDelta
        : state.editorHeight,
    leftPaneWidth:
      direction === ResizeDirection.VERTICAL || state.isRightEditorPaneOpen
        ? state.leftPaneWidth
        : (box.width * state.leftPaneWidth + state.resizeDelta) / box.width,
    rightEditorPaneWidth:
      direction === ResizeDirection.VERTICAL ||
      (state.isSplitPane && !state.isRightEditorPaneOpen)
        ? state.rightEditorPaneWidth
        : state.rightEditorPaneWidth - state.resizeDelta,
    resizeDirection: null,
    resizeStart: 0,
    resizeDelta: 0,
    minResizeDelta: 0,
    maxResizeDelta: 0,
  };
};
const resizeEndReducer = reducer<BaseAppLayoutState, ResizeUpdate>(
  resizeEnd,
  handleResizeEnd
);

const handleResizeCancel = (state: BaseAppLayoutState) => {
  if (state.resizeDirection === null) {
    return state;
  }

  return {
    ...state,
    resizeDirection: null,
    resizeStart: 0,
    resizeDelta: 0,
    minResizeDelta: 0,
    maxResizeDelta: 0,
  };
};
const resizeCancelReducer = reducer<BaseAppLayoutState>(
  resizeCancel,
  handleResizeCancel
);

const isSplitPaneReducer = (
  state: BaseAppLayoutState,
  isSplitPane: boolean
) => ({
  ...state,
  isSplitPane,
});
const splitPaneReducer = streamReducer<BaseAppLayoutState, boolean>(
  isSplitPane$,
  isSplitPaneReducer
);

const isRightEditorPaneOpenReducer = (
  state: BaseAppLayoutState,
  isRightEditorPaneOpen: boolean
) => ({ ...state, isRightEditorPaneOpen });
const isRightPaneOpenReducer = streamReducer<BaseAppLayoutState, boolean>(
  isRightEditorPaneOpen$,
  isRightEditorPaneOpenReducer
);

const isDetailsDrawerOpenReducer = (
  state: BaseAppLayoutState,
  isDetailsDrawerOpen: boolean
) => ({ ...state, isDetailsDrawerOpen });
const detailsDrawerReducer = streamReducer<BaseAppLayoutState, boolean>(
  isDetailsDrawerOpen$,
  isDetailsDrawerOpenReducer
);

const isViewpointModeReducer = (
  state: BaseAppLayoutState,
  { isViewpointMode }: ExtractStreamShape<typeof isViewpointMode$>
) => ({
  ...state,
  isViewpointMode,
  ...(isViewpointMode
    ? {
        isSplitPane: false,
        isRightEditorPaneOpen: false,
      }
    : {}),
});

const stateReducers = [
  contextReducer,
  resizeStartReducer,
  resizeUpdateReducer,
  resizeEndReducer,
  resizeCancelReducer,
  splitPaneReducer,
  isRightPaneOpenReducer,
  detailsDrawerReducer,
  streamReducer(isViewpointMode$, isViewpointModeReducer),
];

const initialState = {
  isGridEditorOpen: false,
  isGridEditorPoppedOut: false,
  isBottomBarVisible: true,
  isEditorPanelVisible: false,
  editorPaneStyle: {},
  contentBarStyle: {},
  editorHeight: DEFAULT_PANEL_HEIGHT,
  resizeDirection: null,
  resizeStart: 0,
  resizeStartDelta: 0,
  resizeDelta: 0,
  minResizeDelta: 0,
  maxResizeDelta: 0,
  resizeStyle: {},
  container: null,
  leftPaneStyle: {},
  isSplitPane: false,
  leftPaneWidth: 0.5,
  isRightEditorPaneOpen: false,
  rightEditorPaneWidth: DEFAULT_RIGHT_PANE_WIDTH,
  isDetailsDrawerOpen: false,
  isViewpointMode: false,
};

export const appLayout$ = action$.pipe(
  reduceState('mainAppLayout$', initialState, stateReducers),
  map<BaseAppLayoutState, MainAppLayoutProps>(state => {
    const {
      isGridEditorOpen,
      isGridEditorPoppedOut,
      isSplitPane,
      resizeDirection,
      isRightEditorPaneOpen,
      rightEditorPaneWidth,
      editorHeight,
      isDetailsDrawerOpen,
      isViewpointMode,
    } = state;

    // TODO CL remove when no longer prototyping new viewpoint mode
    const prototypeNoGridEditor =
      isViewpointMode && hasFeature(Features.CANVAS_PROTOTYPE);
    const isVerticalResizing = resizeDirection === ResizeDirection.VERTICAL;
    const isHorizontalResizing = resizeDirection === ResizeDirection.HORIZONTAL;
    const isEditorPanelVisible = !isGridEditorPoppedOut && isGridEditorOpen;
    const isBottomBarVisible =
      (isGridEditorPoppedOut || !isGridEditorOpen) && !prototypeNoGridEditor;

    const layoutState = {
      ...state,
      isVerticalResizing,
      isHorizontalResizing,
      isEditorPanelVisible,
      isBottomBarVisible,
    };
    const editorPaneStyle = getEditorPaneStyle(layoutState);
    const contentBarStyle = getContentBarStyle(layoutState);
    const verticalResizeStyle = getVerticalResizeStyle(layoutState);
    const leftPaneStyle = getLeftPaneStyle(layoutState);
    const rightPaneStyle = getRightPaneStyle(layoutState);
    const horizontalResizeStyle = getHorizontalResizeStyle(layoutState);

    const rightEditorPaneStyle = getRightEditorPaneStyle(layoutState);

    return {
      isBottomBarVisible,
      isEditorPanelVisible,
      editorPaneStyle,
      contentBarStyle,
      verticalResizeStyle,
      isVerticalResizing,
      isHorizontalResizing,
      leftPaneStyle,
      isSplitPane,
      rightPaneStyle,
      horizontalResizeStyle,
      isRightEditorPaneOpen,
      rightEditorPaneStyle,
      rightEditorPaneWidth,
      editorHeight,
      isDetailsDrawerOpen,
      isViewpointMode,
    };
  })
);
