import { actionCreator } from '@ardoq/rxbeach';
import { MappedComponent, MappedReference, MappingsState } from './types';
import { AsyncStatus } from 'integrations/common/types/api';

const NAMESPACE = '[integrations lucid-import] Mappings';

export const initMappings = actionCreator<
  Pick<MappingsState, 'components' | 'references'>
>(`${NAMESPACE}_INIT_MAPPINGS`);

export const setComponentMapping = actionCreator<MappedComponent>(
  `${NAMESPACE}_SET_COMPONENT_MAPPING`
);

export const addComponentMapping = actionCreator<MappedComponent>(
  `${NAMESPACE}_ADD_COMPONENT_MAPPING`
);

export const deleteComponentMapping = actionCreator<MappedComponent>(
  `${NAMESPACE}_DELETE_COMPONENT_MAPPING`
);

export const setReferenceMapping = actionCreator<MappedReference>(
  `${NAMESPACE}_SET_REFERENCE_MAPPING`
);

export const addReferenceMapping = actionCreator<MappedReference>(
  `${NAMESPACE}_ADD_REFERENCE_MAPPING`
);

export const deleteReferenceMapping = actionCreator<MappedReference>(
  `${NAMESPACE}_DELETE_REFERENCE_MAPPING`
);

export const setMappingsStatus = actionCreator<AsyncStatus>(
  `${NAMESPACE}_SET_MAPPINGS_STATUS`
);

export const importMappings = actionCreator(`${NAMESPACE}_IMPORT_MAPPINGS`);

export const resetMappings = actionCreator(`${NAMESPACE}_RESET_MAPPINGS`);
