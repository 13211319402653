import { s24 } from '@ardoq/design-tokens';
import { PageBody, PageWrapper } from '@ardoq/page-layout';
import { Inventory as InventoryUI } from '@ardoq/inventory';
import Navbar from '../views/navbar/Navbar';
import { DatasourceSelectionRepresentation } from './DatasourceSelectionRepresentation';
import { ConnectedInventoryInteractiveTopRow } from './interactiveTopRow/ConnectedInventoryInteractiveTopRow';
import { GhostButton, PrimaryButton } from '@ardoq/button';
import { InventoryAdapterProps } from './types';
import { isEmpty } from 'lodash';
import { FlexBox } from '@ardoq/layout';
import {
  ArdoqLoaderComponent,
  LoaderColor,
  LoaderSize,
} from '@ardoq/ardoq-loader-component';
import { connect } from '@ardoq/rxbeach';
import { inventoryViewModel$ } from './inventory$';
// eslint-disable-next-line ardoq-lint/no-packages-src-import
import '@ardoq/inventory/dist/inventory.css';
import { ChevronLeftIcon } from '@ardoq/icons';

const Inventory = ({
  locale,
  initialColumnDefinitions,
  inventoryCommands,
  getNextRowsBatch,
  error,
  datasourceSelection,
  hasEditPermissionForComponent,
  users,
  initialScopeData,
  canEditWorkspace,
  isSavedAsReportDisabled,
}: InventoryAdapterProps) => (
  <PageWrapper>
    <Navbar
      primaryContent={
        <DatasourceSelectionRepresentation
          datasourceSelection={datasourceSelection}
          scopeData={initialScopeData}
        />
      }
      secondaryContent="Inventory"
      primaryButton={
        <PrimaryButton
          onClick={inventoryCommands.saveAsReport}
          isDisabled={isSavedAsReportDisabled}
        >
          Save as report
        </PrimaryButton>
      }
      secondaryButton={
        <GhostButton onClick={inventoryCommands.navigateToDataOverview}>
          <ChevronLeftIcon />
          Back to Data Overview
        </GhostButton>
      }
    />
    <PageBody backgroundColor="surfaceDefault" padding={`0 ${s24}`}>
      {isEmpty(initialColumnDefinitions) ? (
        <FlexBox flex={1} justify="center" align="center">
          <ArdoqLoaderComponent
            size={LoaderSize.MEDIUM}
            loaderColor={LoaderColor.BRAND}
          />
        </FlexBox>
      ) : (
        <FlexBox flexDirection="column" gap="small" height="full">
          <ConnectedInventoryInteractiveTopRow />
          <InventoryUI
            getNextRowsBatch={getNextRowsBatch}
            initialColumnDefinitions={initialColumnDefinitions}
            locale={locale}
            commands={inventoryCommands}
            error={error}
            datasourceSelection={datasourceSelection}
            hasEditPermissionForComponent={hasEditPermissionForComponent}
            users={users}
            initialScopeData={initialScopeData}
            canEditWorkspace={canEditWorkspace}
          />
        </FlexBox>
      )}
    </PageBody>
  </PageWrapper>
);

export default connect(Inventory, inventoryViewModel$);
