import { api, handleError, inventoryApi } from '@ardoq/api';
import {
  APIComponentType,
  APIFieldType,
  APIOrganizationUser,
  APIReportAttributes,
  ArdoqId,
  BooleanOperator,
  ReportColumnType,
  ZonesBySubdivisionsIds,
} from '@ardoq/api-types';
import { isArdoqError, isJust, Maybe } from '@ardoq/common-helpers';
import { EnhancedScopeData } from '@ardoq/data-model';
import {
  ComponentTypeInventoryDatasourceSelection,
  GetNextRowsBatchFunction,
  InventoryColumnDefinitionWithSelectionState,
  InventoryDatasourceSelection,
  ReportInventoryDatasourceSelection,
  WorkspaceInventoryDatasourceSelection,
} from '@ardoq/inventory';
import { logError } from '@ardoq/logging';
import { reportBuilderOperations } from '@ardoq/report-builder';
import {
  EnhancedSearchResponse,
  searchResultsOperations,
} from '@ardoq/report-reader';
import { dispatchAction, reducedStream, reducer } from '@ardoq/rxbeach';
import { enhancedScopeDataOperations } from '@ardoq/scope-data';
import { isEmpty, isEqual, pick } from 'lodash';
import { componentAccessControlOperation } from 'resourcePermissions/accessControlHelpers/component';
import { combineLatest, Observable } from 'rxjs';
import {
  distinctUntilChanged,
  filter,
  map,
  startWith,
  switchMap,
} from 'rxjs/operators';

import currentUser$, {
  CurrentUserState,
} from 'streams/currentUser/currentUser$';
import currentUserPermissionContext$ from 'streams/currentUserPermissions/currentUserPermissionContext$';
import { models$ } from 'streams/models/models$';
import { orgUsers$ } from 'streams/orgUsers/orgUsers$';
import reports$ from 'streams/reports/reports$';
import subdivisions$ from 'streams/subdivisions/subdivisions$';
import workspaces$ from 'streams/workspaces/workspaces$';
import { locale$ } from '../streams/locale$';
import {
  componentFieldValueWasUpdated,
  ComponentFieldValueWasUpdatedPayload,
} from './actions';
import { apiColumnDefinitionToColumWithOptionsAndSort } from './apiColumnToInventoryColumnWithOptions';
import { getInventoryCommands } from './inventoryCommands';
import { inventoryGridApi$ } from './inventoryGridApi$';
import inventoryNavigation$ from './inventoryNavigation$';
import { inventoryOperations } from './inventoryOperations';
import {
  InventoryAdapterProps,
  InventoryColumnDefinitionWithOptionList,
  InventoryState,
} from './types';
import { gotNextRowsBatch } from './interactiveTopRow/actions';
import { workspaceAccessControlInterface } from 'resourcePermissions/accessControlHelpers/workspace';
import { reportAccessControlInterface } from '../resourcePermissions/accessControlHelpers/report';

const getSubdivisionsFromDataSourceInput = (
  datasource:
    | ComponentTypeInventoryDatasourceSelection
    | WorkspaceInventoryDatasourceSelection
    | ReportInventoryDatasourceSelection
): ZonesBySubdivisionsIds | undefined => {
  switch (datasource.dataSourceType) {
    case 'componentType':
      return undefined;
    case 'workspace':
      return undefined;
    case 'report':
      return datasource.report.subdivisions ?? undefined;
    default:
      datasource satisfies never;
      return undefined;
  }
};

const getGetNextRowsBatch = (
  dataSource:
    | ComponentTypeInventoryDatasourceSelection
    | WorkspaceInventoryDatasourceSelection
    | ReportInventoryDatasourceSelection
): GetNextRowsBatchFunction => {
  return async paramsInArdoqFormat => {
    const subdivisions = getSubdivisionsFromDataSourceInput(dataSource);
    const workspaceIds =
      inventoryOperations.getWorkspaceIdsFromDatasource(dataSource);
    const subquery = inventoryOperations.getSubqueryFromDatasourceAndParams(
      dataSource,
      paramsInArdoqFormat.subquery
    );

    const resultsOrError = await inventoryApi.fetchInventoryData({
      subdivisions,
      workspaceIds,
      ...paramsInArdoqFormat,
      subquery,
    });

    if (isArdoqError(resultsOrError)) {
      logError(resultsOrError);
      return {
        error: resultsOrError,
        status: 'error',
      };
    }
    dispatchAction(
      gotNextRowsBatch({ totalNumberOfRows: resultsOrError.totalNumberOfRows })
    );
    const enhancedResult =
      searchResultsOperations.mergeDateRangeFieldsAndEnhanceScopeData(
        resultsOrError
      );
    return {
      status: 'success',
      result: {
        scopeData: enhancedResult.scopeData!,
        rows: enhancedResult.results,
        totalNumberOfRows: enhancedResult.totalNumberOfRows,
      },
    };
  };
};

const hasComponentTypeName = (
  componentType: APIComponentType,
  componentTypeNames: string[]
): boolean => {
  if (componentTypeNames.includes(componentType.name)) {
    return true;
  }
  if (!isEmpty(componentType.children)) {
    return Object.values(componentType.children).some(child =>
      hasComponentTypeName(child, componentTypeNames)
    );
  }
  return false;
};

export const selectedDataSetDatasource$: Observable<
  | WorkspaceInventoryDatasourceSelection
  | ReportInventoryDatasourceSelection
  | ComponentTypeInventoryDatasourceSelection
> = combineLatest({
  navigationState: inventoryNavigation$,
  reports: reports$,
  models: models$,
  workspaces: workspaces$,
}).pipe(
  map(
    ({
      navigationState,
      reports,
      models,
      workspaces,
    }): InventoryDatasourceSelection => {
      switch (navigationState.dataSourceType) {
        case 'none':
          return { dataSourceType: 'none' };
        case 'componentType': {
          const modelIds = models.models
            .filter(model => {
              return Object.values(model.root).some(componentType =>
                hasComponentTypeName(
                  componentType,
                  navigationState.componentTypeNames
                )
              );
            })
            .map(model => model._id);

          const workspaceIds = workspaces.models
            .filter(workspace => modelIds.includes(workspace.componentModel))
            .map(workspace => workspace._id);

          return {
            componentTypeNames: navigationState.componentTypeNames,
            dataSourceType: 'componentType',
            workspaceIds,
          };
        }
        case 'workspace':
          return {
            selectedWorkspaceIds: navigationState.selectedWorkspaceIds,
            dataSourceType: 'workspace',
          };
        case 'report':
          return reports.byId[navigationState.selectedReportId]
            ? {
                report: reports.byId[navigationState.selectedReportId],
                dataSourceType: 'report',
              }
            : { dataSourceType: 'none' };
        default:
          navigationState satisfies never;
          return { dataSourceType: 'none' };
      }
    }
  ),
  filter(
    datasource =>
      inventoryOperations.isWorkspaceInventoryDatasourceSelection(datasource) ||
      inventoryOperations.isReportInventoryDatasourceSelection(datasource) ||
      inventoryOperations.isComponentTypeInventoryDatasourceSelection(
        datasource
      )
  ),
  distinctUntilChanged((a, b) => isEqual(a, b))
);

const toReportInventoryDataParams = (report: APIReportAttributes) => {
  const sort = reportBuilderOperations.getSortKeyAndOrder(report);
  const query = report.query;
  return {
    from: 0,
    size: 0,
    workspaceIds: report?.workspaceIds ?? [],
    subdivisions: report.subdivisions ?? undefined,
    sorting: sort.columnKey
      ? { sortBy: sort.columnKey, order: sort.order }
      : undefined,
    subquery:
      query && typeof query !== 'string' // Gremlin reports have a string query, but they shouldn't ever end up here in the first place
        ? query.rules[1]
        : {
            condition: BooleanOperator.AND,
            rules: [],
          },
  };
};

const toWorkspaceInventoryDataParams = (workspaceIds: ArdoqId[]) => ({
  from: 0,
  size: 0,
  workspaceIds,
  sorting: undefined,
  subquery: {
    condition: BooleanOperator.AND,
    rules: [],
  },
});

const toComponentTypeInventoryDataParams = (
  dataSource: ComponentTypeInventoryDatasourceSelection
) => {
  return {
    from: 0,
    size: 0,
    workspaceIds: dataSource.workspaceIds,
    sorting: undefined,
    subquery: inventoryOperations.getSubqueryFromComponentTypeNames(
      dataSource.componentTypeNames
    ),
  };
};

const fetchInventoryData$ = selectedDataSetDatasource$.pipe(
  map(datasource => {
    switch (datasource.dataSourceType) {
      case 'report':
        return toReportInventoryDataParams(datasource.report);
      case 'componentType':
        return toComponentTypeInventoryDataParams(datasource);
      case 'workspace':
        return toWorkspaceInventoryDataParams(datasource.selectedWorkspaceIds);
      default:
        datasource satisfies never;
        return toWorkspaceInventoryDataParams([]);
    }
  }),
  switchMap(inventoryApi.fetchInventoryData),
  handleError(error => {
    api.logErrorIfNeeded(error);
    return [];
  }),
  map(searchResultsOperations.mergeDateRangeFieldsAndEnhanceScopeData),
  startWith(null)
);

const inventoryDatasourceToGetNextRowsBatchInitialScopeDataAndColumnDefinitions =
  ({
    searchResults,
    users,
    currentUser,
    datasource,
  }: InventoryInputArgs): InventoryInput => {
    const getNextRowsBatch = getGetNextRowsBatch(datasource);

    if (!searchResults) {
      return {
        initialColumnDefinitions: [],
        allAvailableColumns: [],
        getNextRowsBatch,
        initialScopeData: enhancedScopeDataOperations.getEmpty(),
      };
    }

    const allAvailableColumns = [
      ...searchResults.columns
        .filter(column => {
          const fieldDefinition =
            searchResults.scopeData &&
            enhancedScopeDataOperations.getFieldByName(
              searchResults.scopeData,
              column.key
            );
          // Filter out the columns that don't apply to any component types. I.e. reference field columns
          // Default fields, like name, description etc., don't have field definitions in the scope data,
          // but they implicitly apply to all component types.
          return !fieldDefinition || fieldDefinition.componentType.length;
        })
        .map(column =>
          apiColumnDefinitionToColumWithOptionsAndSort(
            searchResults.scopeData,
            column,
            users
          )
        ),
      {
        key: 'rootWorkspace',
        label: 'Workspace',
        type: ReportColumnType.FIELD,
        dataType: APIFieldType.LIST,
        options:
          searchResults.scopeData?.workspaces.map(workspace => ({
            label: workspace.name,
            value: workspace._id,
          })) ?? [],
      },
    ];
    return {
      initialColumnDefinitions: inventoryOperations.applyColumnSelection({
        allAvailableColumns,
        currentUser,
        datasource,
      }),
      initialScopeData:
        searchResults.scopeData ?? enhancedScopeDataOperations.getEmpty(),
      getNextRowsBatch,
      allAvailableColumns,
    };
  };

type InventoryInputArgs = {
  searchResults: Maybe<EnhancedSearchResponse>;
  users: APIOrganizationUser[];
  currentUser: CurrentUserState;
  datasource:
    | ReportInventoryDatasourceSelection
    | WorkspaceInventoryDatasourceSelection
    | ComponentTypeInventoryDatasourceSelection;
};

type InventoryInput = {
  initialScopeData: EnhancedScopeData;
  getNextRowsBatch: GetNextRowsBatchFunction;
  initialColumnDefinitions: InventoryColumnDefinitionWithSelectionState[];
  allAvailableColumns: InventoryColumnDefinitionWithOptionList[];
};

const inventoryInput$: Observable<InventoryInput> = combineLatest({
  searchResults: fetchInventoryData$,
  datasource: selectedDataSetDatasource$,
  orgUsers: orgUsers$,
  currentUser: currentUser$,
}).pipe(
  map(({ searchResults, datasource, orgUsers: { users }, currentUser }) => {
    return {
      searchResults,
      users,
      currentUser,
      datasource,
    };
  }),
  distinctUntilChanged((a, b) =>
    isEqual(
      pick(a, ['datasource', 'searchResults']),
      pick(b, ['datasource', 'searchResults'])
    )
  ),
  map(inventoryDatasourceToGetNextRowsBatchInitialScopeDataAndColumnDefinitions)
);

const defaultInventoryInitState: InventoryState = {
  error: null,
  updatePayload: null,
};

const setErrorAndUpdatePayload = (
  state: InventoryState,
  errorPayload: Maybe<ComponentFieldValueWasUpdatedPayload>
): InventoryState =>
  isJust(errorPayload)
    ? { ...state, ...errorPayload }
    : {
        ...state,
        error: null,
        updatePayload: null,
      };

export const inventory$ = reducedStream<InventoryState>(
  'inventory',
  defaultInventoryInitState,
  [reducer(componentFieldValueWasUpdated, setErrorAndUpdatePayload)]
);

export const inventoryViewModel$ = combineLatest({
  locale: locale$,
  inventoryState: inventory$,
  datasource: selectedDataSetDatasource$,
  inventoryInput: inventoryInput$,
  permissionContext: currentUserPermissionContext$,
  subdivisionsContext: subdivisions$,
  orgUsers: orgUsers$,
  inventoryGridApi: inventoryGridApi$,
}).pipe(
  map(
    ({
      locale,
      inventoryState,
      datasource,
      inventoryInput: {
        getNextRowsBatch,
        initialColumnDefinitions,
        allAvailableColumns,
        initialScopeData,
      },
      permissionContext,
      subdivisionsContext,
      orgUsers: { users },
      inventoryGridApi,
    }): InventoryAdapterProps => {
      return {
        locale,
        initialColumnDefinitions,
        inventoryCommands: getInventoryCommands(
          {
            allAvailableColumns,
            datasource,
          },
          inventoryState.updatePayload,
          inventoryGridApi
        ),
        getNextRowsBatch,
        error: inventoryState.error,
        datasourceSelection: datasource,
        initialScopeData,
        hasEditPermissionForComponent: component =>
          componentAccessControlOperation.canEditComponent({
            component,
            permissionContext,
            subdivisionsContext,
          }),
        canEditWorkspace: workspaceId =>
          workspaceAccessControlInterface.canEditWorkspace(
            permissionContext,
            workspaceId,
            null
          ),
        users,
        isSavedAsReportDisabled:
          !reportAccessControlInterface.canCreateReport(permissionContext),
      };
    }
  )
);
