import {
  dispatchAction,
  extractPayload,
  ofType,
  routine,
} from '@ardoq/rxbeach';
import { EMPTY, filter, from, of } from 'rxjs';
import {
  catchError,
  map,
  switchMap,
  tap,
  withLatestFrom,
} from 'rxjs/operators';
import { activeIntegrations$ } from 'integrations/common/streams/activeIntegrations/activeIntegrations$';
import { tabularMappings$ } from 'integrations/common/streams/tabularMappings/getTabularMappingStream';
import {
  equivalentTabularMappings,
  removeIndexOutOfBoundFilters,
  tabularMappingIntoTransferConfig,
  transferConfigToTabularMapping,
} from 'integrations/common/streams/tabularMappings/utils';
import { transferConfigs$ } from 'integrations/common/streams/transferConfigs/transferConfigs$';
import { getCurrentTransferConfig } from 'integrations/common/streams/transferConfigs/utils';
import * as actions from './actions';
import { updateTabularMapping } from './actions';
import * as api from './api';
import { userEvent } from 'sync/actions';
import {
  resetIntegration,
  setCurrentTableId,
} from 'integrations/common/streams/activeIntegrations/actions';
import { tablePreviews$ } from 'integrations/common/streams/tablePreviews/getTablePreviewsStream';
import { fields$ } from 'integrations/common/streams/fields/fields$';
import { workspaces$ } from 'integrations/common/streams/workspaces/workspaces$';
import { transferState$ } from 'integrations/common/streams/transferState/getTransferStateStream';
import {
  ensureCorrectAsyncRequest,
  parseAsyncResponsePayload,
} from 'integrations/common/async/utils';
import { AsyncOperations } from 'integrations/common/async/constants';
import { TransferDirection, TransferResponse } from './types';
import { trackIntegrationEvent } from 'integrations/common/tracking/actions';
import {
  applyTabularMapping,
  clearTabularMapping,
} from 'integrations/common/streams/tabularMappings/actions';
import { schedule$ } from 'integrations/common/streams/schedule/schedule$';
import { showToast, ToastType } from '@ardoq/status-ui';
import { DEFAULT_TRANSFER_CONFIG } from '../transferConfigs/constants';
import { setUpsertedSchedule } from '../schedule/actions';
import { extractErrorData } from 'integrations/common/utils/extractErrorData';

const handleImport = routine(
  ofType(actions.importRun),
  extractPayload(),
  withLatestFrom(
    activeIntegrations$,
    tabularMappings$,
    transferConfigs$,
    tablePreviews$
  ),
  switchMap(
    ([
      { integrationId, async, dryRun, sourceConfig },
      activeIntegrations,
      tabularMappings,
      transferConfigs,
      tablePreviews,
    ]) => {
      const activeIntegration = activeIntegrations[integrationId];
      const funnelId = activeIntegrations[integrationId].trackingFunnelId;
      const tabularMapping = tabularMappings[integrationId];
      const tablePreview = tablePreviews[integrationId];
      const transferConfig = getCurrentTransferConfig(
        transferConfigs.configs,
        activeIntegration
      );
      const sourceId = activeIntegration.sourceId;

      if (tabularMapping && sourceId) {
        dispatchAction(
          actions.startTransfer({
            integrationId,
            tabularMapping,
            async,
            dryRun,
          })
        );
        const config = tabularMappingIntoTransferConfig(
          removeIndexOutOfBoundFilters(tabularMapping, tablePreview),
          transferConfig
        );
        return of({
          async,
          dryRun,
          sourceId,
          integrationId,
          funnelId,
          config: {
            ...config,
            ...(sourceConfig ? { sourceConfig } : {}),
            name: config.name || activeIntegration.integrationName,
          },
        });
      }

      return EMPTY;
    }
  ),
  switchMap(payload => {
    const integrationId = payload.integrationId;

    return from(api.importRun(payload)).pipe(
      catchError(error => {
        dispatchAction(
          actions.transferFailure({
            dryRun: payload.dryRun,
            integrationId,
            errorMessage: error.message,
            errorData: extractErrorData(error),
          })
        );
        return EMPTY;
      }),
      map(response => ({ integrationId, response, payload }))
    );
  }),
  tap(({ response, integrationId, payload }) => {
    if (!response.async) {
      dispatchAction(
        actions.transferSuccess({
          integrationId,
          response: response,
          dryRun: payload.dryRun,
          transferDirection: TransferDirection.IMPORT,
        })
      );
    }
  })
);

const handleImportSocketResponse = routine(
  ofType(userEvent),
  extractPayload(),
  map(parseAsyncResponsePayload<TransferResponse>),
  withLatestFrom(activeIntegrations$),
  ensureCorrectAsyncRequest([
    AsyncOperations.IMPORT,
    AsyncOperations.IMPORT_DRY_RUN,
  ]),
  tap(([response]) => {
    if (!response) {
      return;
    }

    if (response.status !== 'success') {
      dispatchAction(
        actions.transferFailure({
          dryRun: response.operation === AsyncOperations.IMPORT_DRY_RUN,
          integrationId: response.integrationId,
          errorMessage: `Failed to import: ${response.data.message ?? ''}`,
          errorData: extractErrorData(response.data),
        })
      );

      return;
    }

    dispatchAction(
      actions.transferSuccess({
        integrationId: response.integrationId,
        response: response.data,
        dryRun: response.operation === AsyncOperations.IMPORT_DRY_RUN,
        transferDirection: TransferDirection.IMPORT,
      })
    );
  })
);

const resetUpsertedScheduleOnReImport = routine(
  ofType(actions.transferSuccess),
  extractPayload(),
  filter(({ dryRun }) => !dryRun),
  tap(({ integrationId }) => {
    dispatchAction(
      setUpsertedSchedule({
        integrationId,
        scheduleStage: 'toBeScheduled',
      })
    );
  })
);

const fillTabularMappingWithWorkspaceIds = routine(
  ofType(actions.transferSuccess),
  extractPayload(),
  filter(
    ({ transferDirection, dryRun }) =>
      transferDirection === TransferDirection.IMPORT && !dryRun
  ),
  withLatestFrom(
    tabularMappings$,
    tablePreviews$,
    fields$,
    workspaces$,
    activeIntegrations$
  ),
  tap(
    ([
      { integrationId },
      tabularMappings,
      tablePreviews,
      fields,
      workspaces,
      activeIntegrations,
    ]) => {
      const tabularMapping = tabularMappings[integrationId];
      const integrationTablePreviews = tablePreviews[integrationId];
      const transferConfig = tabularMappingIntoTransferConfig(
        tabularMapping,
        DEFAULT_TRANSFER_CONFIG
      );
      const filledTabularMapping = transferConfigToTabularMapping({
        transferConfig: transferConfig,
        tablePreviews: integrationTablePreviews,
        allFields: fields.all,
        allWorkspaces: workspaces.existing,
        mapColumnsBy:
          activeIntegrations[integrationId].integrationMappingParams
            .mapColumnsBy,
        mapTablesBy:
          activeIntegrations[integrationId].integrationMappingParams
            .mapTablesBy,
      });

      dispatchAction(
        applyTabularMapping({
          integrationId: integrationId,
          mapping: filledTabularMapping,
        })
      );

      dispatchAction(
        updateTabularMapping({
          integrationId,
          tabularMapping: filledTabularMapping,
        })
      );
    }
  )
);

const trackStartImport = routine(
  ofType(actions.startTransfer),
  extractPayload(),
  withLatestFrom(transferState$),
  tap(([{ integrationId, dryRun }, transferState]) => {
    const transferDirection = transferState[integrationId].transferDirection;
    const isImport = transferDirection === TransferDirection.IMPORT;
    dispatchAction(
      trackIntegrationEvent(
        dryRun
          ? {
              integrationId,
              name: 'TRIGGERED_TEST',
              metadata: { transferDirection },
            }
          : {
              integrationId,
              name: isImport ? 'TRIGGERED_IMPORT' : 'TRIGGERED_EXPORT',
            }
      )
    );
  })
);

const trackImportFailure = routine(
  ofType(actions.transferFailure),
  extractPayload(),
  withLatestFrom(transferState$),
  tap(([{ integrationId, dryRun }, transferState]) => {
    const transferDirection = transferState[integrationId].transferDirection;
    const isImport = transferDirection === TransferDirection.IMPORT;
    dispatchAction(
      trackIntegrationEvent(
        dryRun
          ? {
              integrationId,
              name: 'FAILED_TEST',
              metadata: { transferDirection },
            }
          : {
              integrationId,
              name: isImport ? 'FAILED_IMPORT' : 'FAILED_EXPORT',
            }
      )
    );
  })
);

const trackResponseStatus = routine(
  ofType(actions.transferSuccess),
  extractPayload(),
  withLatestFrom(transferState$),
  tap(([{ integrationId, response, dryRun }, transferState]) => {
    const transferDirection = transferState[integrationId].transferDirection;
    const isImport = transferDirection === TransferDirection.IMPORT;

    const hasErrors = Object.values(response.tables).some(table => {
      return table.validation.some(complaint => complaint.level === 'error');
    });

    const hasWarnings = Object.values(response.tables).some(table => {
      return table.validation.some(complaint => complaint.level === 'warn');
    });

    if (hasWarnings) {
      dispatchAction(
        trackIntegrationEvent(
          dryRun
            ? {
                integrationId,
                name: 'WARNED_TEST',
                metadata: { transferDirection },
              }
            : {
                integrationId,
                name: isImport ? 'WARNED_IMPORT' : 'WARNED_EXPORT',
              }
        )
      );
    }

    if (hasErrors) {
      dispatchAction(
        trackIntegrationEvent(
          dryRun
            ? {
                integrationId,
                name: 'ERRORED_TEST',
                metadata: { transferDirection },
              }
            : {
                integrationId,
                name: isImport ? 'ERRORED_IMPORT' : 'ERRORED_EXPORT',
              }
        )
      );
      return;
    }

    dispatchAction(
      trackIntegrationEvent(
        dryRun
          ? {
              integrationId,
              name: 'SUCCEEDED_TEST',
              metadata: { transferDirection },
            }
          : {
              integrationId,
              name: isImport ? 'SUCCEEDED_IMPORT' : 'SUCCEEDED_EXPORT',
            }
      )
    );
  })
);

const resetCurrentTableIdOnImportSuccess = routine(
  ofType(actions.transferSuccess),
  extractPayload(),
  withLatestFrom(activeIntegrations$),
  tap(([{ integrationId }, activeIntegrations]) => {
    const currentTableId = activeIntegrations[integrationId].currentTableId;
    if (currentTableId) {
      // for successful imports, we need to set the current table again
      // this will ensure we keep the table data consistent
      // when the user nagivates back to configure step
      dispatchAction(setCurrentTableId({ integrationId, id: currentTableId }));
    }
  })
);

const displaySaveConfigurationReminder = routine(
  ofType(actions.transferSuccess),
  extractPayload(),
  withLatestFrom(
    activeIntegrations$,
    transferConfigs$,
    tabularMappings$,
    tablePreviews$,
    fields$,
    workspaces$,
    schedule$
  ),
  tap(
    ([
      { integrationId },
      activeIntegrations,
      { configs },
      tabularMappings,
      tablePreviews,
      fields,
      workspaces,
      schedule,
    ]) => {
      const activeIntegration = activeIntegrations[integrationId];
      // if config is new or different from loaded one we display the save modal
      if (
        !equivalentTabularMappings(
          transferConfigToTabularMapping({
            transferConfig: getCurrentTransferConfig(
              configs,
              activeIntegration
            ),
            tablePreviews: tablePreviews[integrationId],
            allFields: fields.all,
            allWorkspaces: workspaces.existing,
            mapTablesBy: activeIntegration.integrationMappingParams.mapTablesBy,
            mapColumnsBy:
              activeIntegration.integrationMappingParams.mapColumnsBy,
          }),
          tabularMappings[integrationId]
        ) &&
        !schedule[integrationId].loadedScheduleId
      ) {
        showToast('Remember to save configuration', ToastType.INFO);
      }
    }
  )
);

const handleResetIntegration = routine(
  ofType(resetIntegration),
  extractPayload(),
  tap(integrationId => {
    dispatchAction(actions.resetTransfer({ integrationId }));
  })
);

const resetImportOnClearTabularMapping = routine(
  ofType(clearTabularMapping),
  extractPayload(),
  tap(({ integrationId }) => {
    dispatchAction(
      actions.resetTransfer({ integrationId, resetTransferDirection: false })
    );
  })
);

export default [
  handleResetIntegration,
  handleImport,
  handleImportSocketResponse,
  resetCurrentTableIdOnImportSuccess,
  resetImportOnClearTabularMapping,
  displaySaveConfigurationReminder,
  trackResponseStatus,
  trackImportFailure,
  trackStartImport,
  fillTabularMappingWithWorkspaceIds,
  resetUpsertedScheduleOnReImport,
];
