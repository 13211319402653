import { CurrentBroadcast, BroadcastFetchStatus } from 'broadcasts/types';
import BroadcastContentForm from './broadcastContentForm/BroadcastContentForm';
import BroadcastAudienceForm from './broadcastAudienceForm/BroadcastAudienceForm';
import ComposeMessagesForm from './composeMessagesForm/ComposeMessagesForm';
import ManageLandingPageForm from './manageLandingPageForm/ManageLandingPageForm';
import BroadcastSchedulerAndReminderForm from './broadcastSchedulerAndReminderForm/BroadcastSchedulerAndReminderForm';
import RightHeaderContent, {
  LeftHeaderContent,
} from 'broadcasts/broadcastBuilder/HeaderContent';
import BroadcastLoader from 'broadcasts/components/BroadcastLoader';
import styled from 'styled-components';
import { BROADCAST_BUILDER_NAMESPACE } from 'broadcasts/consts';
import { DoqLayout, DoqType, DoqWithSpeechBubble } from '@ardoq/doq';
import { connect } from '@ardoq/rxbeach';
import { useEffectOnce } from '@ardoq/hooks';
import { trackOpenedNewbroadcastBuilder } from 'broadcasts/tracking';
import { isPersistedBroadcast } from 'broadcasts/utils';
import BroadcastNavbar from './BroadcastNavbar';
import { PageHeader, PageBody, PageWrapper } from '@ardoq/page-layout';
import { APIOrganizationAttributes } from '@ardoq/api-types';
import { broadcastBuilderViewModel$ } from './broadcastBuilderViewModel$';

const DoqWithSpeechBubbleContainer = styled.div`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export type BroadcastBuilderProps = {
  currentBroadcast: CurrentBroadcast | null;
  fetchStatus: BroadcastFetchStatus;
  richTextEditorResetKey: number;
  organization: APIOrganizationAttributes | null;
  hasNewJourneyFeature: boolean;
};

const BroadcastBuilder = ({
  currentBroadcast,
  fetchStatus,
  richTextEditorResetKey,
  organization,
  hasNewJourneyFeature,
}: BroadcastBuilderProps) => {
  useEffectOnce(() => {
    if (currentBroadcast && !isPersistedBroadcast(currentBroadcast)) {
      trackOpenedNewbroadcastBuilder();
    }
  });

  return (
    <PageWrapper>
      {hasNewJourneyFeature ? (
        <BroadcastNavbar />
      ) : (
        <PageHeader
          title="Broadcast Builder"
          leftContent={
            <LeftHeaderContent
              broadcastName={currentBroadcast?.name ?? 'Untitled Broadcast'}
            />
          }
          rightContent={<RightHeaderContent />}
        />
      )}
      <PageBody
        data-click-namespace={BROADCAST_BUILDER_NAMESPACE}
        alignIslandsToLeft
      >
        <BroadcastLoader status={fetchStatus}>
          {currentBroadcast ? (
            <>
              <BroadcastContentForm
                content={currentBroadcast.content}
                broadcastName={currentBroadcast.name}
              />
              <BroadcastAudienceForm
                content={currentBroadcast.content}
                audiences={currentBroadcast.audiences}
              />
              <ManageLandingPageForm
                content={currentBroadcast.content}
                audienceStartingPage={currentBroadcast.audienceStartingPage}
                audienceStartingPageViewpoint={
                  currentBroadcast.audienceStartingPageViewpoint
                }
                audiences={currentBroadcast.audiences}
              />
              <ComposeMessagesForm
                errors={currentBroadcast.errors}
                warnings={currentBroadcast.warnings}
                message={currentBroadcast.message}
                contentType={currentBroadcast.content.contentType}
                richTextEditorResetKey={richTextEditorResetKey}
                org={organization}
                audiences={currentBroadcast.audiences}
                lastModifiedByEmail={
                  isPersistedBroadcast(currentBroadcast)
                    ? currentBroadcast.lastModifiedByEmail
                    : null
                }
              />
              <BroadcastSchedulerAndReminderForm
                schedule={currentBroadcast.scheduling}
                contentType={currentBroadcast.content.contentType}
              />
            </>
          ) : (
            <DoqWithSpeechBubbleContainer>
              <DoqWithSpeechBubble
                doqType={DoqType.ERROR}
                layout={DoqLayout.DIALOG}
                title="No broadcast selected"
                message="You can create a new broadcast or edit an existing one from the Broadcast Overview."
              />
            </DoqWithSpeechBubbleContainer>
          )}
        </BroadcastLoader>
      </PageBody>
    </PageWrapper>
  );
};

export default connect(BroadcastBuilder, broadcastBuilderViewModel$);
