import {
  TraversalCreatedInViewLoadedState,
  isAnyTraversalLoadedState,
  LoadedState,
  LoadedStateHash,
  Path,
  QueryBuilderQuery,
  StartContextSelectionType,
  TraversalLoadedState,
  TraversalParams,
  TraversalPathMatchingType,
  ManualComponentSelection,
  isTraversalLoadedState,
  APIViewpointAttributes,
} from '@ardoq/api-types';
import { ExcludeFalsy } from '@ardoq/common-helpers';
import { EnhancedScopeData } from '@ardoq/data-model';
import { logError } from '@ardoq/logging';
import { dispatchAction, ObservableState } from '@ardoq/rxbeach';
import { enhancedScopeDataOperations } from '@ardoq/scope-data';
import { ComponentSelection } from 'componentSelection/componentSelectionTypes';
import { InventoryState } from 'componentHierarchies/InventoryState';
import { loadedStateOperations } from 'loadedState/loadedStateOperations';
import { uniq } from 'lodash';

import { Observable, tap, withLatestFrom } from 'rxjs';
import { catchErrorLogWithMessageAndContinue } from 'streams/utils/streamOperators';
import {
  ExecuteUnsavedViewpointPayload,
  executeViewpoint,
} from 'viewpointBuilder/actions';
import {
  openComponents,
  saveEditedLoadedStateSearch,
} from 'viewpointBuilder/selectContextTab/actions';
import { traversalState$ } from '../../streams/traversals$';

export const getHandleSelectionChangeRoutine = (
  componentHierarchySelection$: ObservableState<ComponentSelection>,
  loadedState$: ObservableState<LoadedState[]>,
  inventory$: Observable<InventoryState>
) =>
  componentHierarchySelection$.pipe(
    withLatestFrom(loadedState$, inventory$, traversalState$),
    // The first loaded state is the main loaded state in the canvas prototype.
    tap(
      ([
        { treeSelection },
        [mainLoadedState],
        { enhancedScopeData },
        viewpoints,
      ]) => {
        if (treeSelection.length === 0) {
          return;
        }

        const loadedStateHash = mainLoadedState
          ? loadedStateOperations.stateToHash(mainLoadedState)
          : undefined;

        const types = getTypes(treeSelection, enhancedScopeData);
        if (types.length === 0) {
          logError(Error('No types found in selection'));
          return;
        }
        if (types.length > 1) {
          loadSelectedComponents(treeSelection, loadedStateHash);
          return;
        }
        const type = types[0];
        const paths = getPaths(type, mainLoadedState);

        const loadedStateViewpoint =
          mainLoadedState &&
          isTraversalLoadedState(mainLoadedState) &&
          mainLoadedState.traversalId
            ? viewpoints.byId[mainLoadedState.traversalId]
            : undefined;

        const viewpoint =
          loadedStateViewpoint?.startType === type
            ? loadedStateViewpoint
            : undefined;

        return loadTraversal({
          startSet: treeSelection,
          paths,
          loadedStateHash,
          viewpoint,
        });
      }
    ),
    catchErrorLogWithMessageAndContinue(
      'Error in getHandleSelectionChangeRoutine'
    )
  );

const loadSelectedComponents = (
  componentIds: string[],
  loadedStateHash?: string
) => {
  if (componentIds.length === 0) {
    return;
  }
  const componentSelection: ManualComponentSelection = {
    startSet: componentIds,
    startContextSelectionType: StartContextSelectionType.MANUAL_SELECTION,
  };

  if (loadedStateHash) {
    dispatchAction(
      saveEditedLoadedStateSearch({
        loadedStateHash,
        componentSelection,
      })
    );
  } else {
    dispatchAction(
      openComponents({
        componentSelection,
      })
    );
  }
};

const getTypes = (
  selection: string[],
  enhancedScopeData: EnhancedScopeData
): string[] => {
  const typeNames = uniq(
    selection.map(
      componentId =>
        enhancedScopeDataOperations.getComponentTypeByComponentId(
          enhancedScopeData,
          componentId
        )?.name
    )
  );
  if (!typeNames.every(Boolean)) {
    logError(
      Error('Not all types are found in `getHandleSelectionChangeRoutine`')
    );
  }
  return typeNames.filter(ExcludeFalsy);
};

const getPaths = (type: string, loadedState?: LoadedState): Path[] => {
  if (!(loadedState && isAnyTraversalLoadedState(loadedState))) {
    return [];
  }
  const currentType = getStartTypeFromLoadedState(loadedState);
  if (!currentType) {
    return [];
  }
  return type === currentType ? loadedState.data.paths : [];
};

const getStartTypeFromLoadedState = (
  loadedState: TraversalLoadedState | TraversalCreatedInViewLoadedState
) => {
  const firstTriple = loadedState.data.paths[0]?.[0];
  if (!firstTriple) {
    return null;
  }
  const { direction, sourceType, targetType } = firstTriple;
  return direction === 'outgoing' ? sourceType : targetType;
};

const loadTraversal = ({
  loadedStateHash,
  startSet = [],
  startQuery = {} as QueryBuilderQuery,
  startContextSelectionType = StartContextSelectionType.MANUAL_SELECTION,
  paths = [],
  filters = {},
  pathCollapsingRules = [],
  pathMatching = TraversalPathMatchingType.LOOSE,
  viewpoint,
}: Partial<ExecuteUnsavedViewpointPayload> & {
  startQuery?: QueryBuilderQuery;
  startSet?: string[];
  startContextSelectionType?: StartContextSelectionType;
  loadedStateHash?: LoadedStateHash;
  viewpoint?: APIViewpointAttributes;
}) => {
  const traversalParams: TraversalParams =
    startContextSelectionType === StartContextSelectionType.MANUAL_SELECTION
      ? {
          paths,
          filters,
          pathCollapsingRules,
          pathMatching,
          componentSelection: {
            startSet,
            startContextSelectionType,
          },
        }
      : {
          paths,
          filters,
          pathCollapsingRules,
          pathMatching,
          componentSelection: {
            startQuery,
            startContextSelectionType,
          },
        };

  if (viewpoint) {
    const { groupBys, conditionalFormatting } = viewpoint;
    dispatchAction(
      executeViewpoint({
        type: 'EXECUTE_SAVED_VIEWPOINT',
        loadedStateHash,
        groupBys: groupBys ?? [],
        conditionalFormatting: conditionalFormatting ?? [],
        viewpointId: viewpoint._id,
        viewName: viewpoint.viewName,
        ...traversalParams,
      })
    );
  } else {
    dispatchAction(
      executeViewpoint({
        type: 'EXECUTE_UNSAVED_VIEWPOINT',
        loadedStateHash,
        ...traversalParams,
      })
    );
  }
};
