import { APIFieldType } from '@ardoq/api-types';
import {
  IntegrationReferenceDirection,
  ReferenceMapping,
  MissingComponentsStrategy,
  MissingParentComponentsStrategy,
  ParentMapping,
} from '@ardoq/api-types/integrations';
import { ColumnMapping } from 'integrations/common/streams/transferConfigs/types';
import { IconSize, IconName } from '@ardoq/icons';
import { fieldOps } from 'models/utils/fieldOps';

export const COLUMN_TYPES: Record<ColumnMapping['columnType'], string> = {
  'ardoq-oid': 'Ardoq OID',
  component: 'Component name',
  'component-type': 'Type',
  'custom-id': 'Custom ID',
  description: 'Description',
  'display-text': 'Display text',
  field: 'Field',
  parent: 'Parent component',
  reference: 'Reference',
  'reference-type': 'Type',
  'root-reference': 'Source',
  'target-reference': 'Target',
  tags: 'Tags',
};

const REFERENCE_FORMATS: Record<ReferenceMapping['referenceFormat'], string> = {
  path: 'Component path',
  'ardoq-oid': 'Ardoq OID',
  'custom-id': 'Custom ID',
};

const PARENT_FORMATS: Record<ParentMapping['parentFormat'], string> = {
  path: 'Component path',
  'ardoq-oid': 'Ardoq OID',
  'custom-id': 'Custom ID',
};

const REFERENCE_DIRECTIONS: Record<IntegrationReferenceDirection, string> = {
  outgoing: 'Target component',
  incoming: 'Source component',
};

const REFERENCE_IF_COMPONENT_IS_MISSING: Record<
  MissingComponentsStrategy,
  string
> = {
  create: 'Create new component',
  error: 'Block the import',
  // warn and exclude-from-import are the same
  // initially for Reference mapping for components, it used warn
  // going forward exclude-from-import is used
  warn: 'Exclude reference',
  'exclude-from-import': 'Exclude reference',
};

const PARENT_IF_COMPONENT_IS_MISSING: Record<
  MissingParentComponentsStrategy,
  string
> = {
  error: 'Block the import',
  'create-as-top-level': 'Import as top-level components',
};

const MAPPABLE_FIELD_TYPES = [
  APIFieldType.CHECKBOX,
  APIFieldType.DATE_ONLY,
  APIFieldType.DATE_TIME,
  APIFieldType.EMAIL,
  APIFieldType.FILE,
  APIFieldType.LIST,
  APIFieldType.NUMBER,
  APIFieldType.SELECT_MULTIPLE_LIST,
  APIFieldType.TEXT_AREA,
  APIFieldType.TEXT,
  APIFieldType.URL,
] as const;

type MappableFieldType = (typeof MAPPABLE_FIELD_TYPES)[number];

const FIELD_TYPE_ICONS: Record<MappableFieldType, IconName> = {
  [APIFieldType.CHECKBOX]: IconName.CHECKBOX_ITEM,
  [APIFieldType.DATE_ONLY]: IconName.DATE,
  [APIFieldType.DATE_TIME]: IconName.DATE_TIME,
  [APIFieldType.EMAIL]: IconName.AT,
  [APIFieldType.FILE]: IconName.FILE_UPLOADED,
  [APIFieldType.LIST]: IconName.LIST,
  [APIFieldType.NUMBER]: IconName.NUMBER,
  [APIFieldType.SELECT_MULTIPLE_LIST]: IconName.SELECT_LIST,
  [APIFieldType.TEXT_AREA]: IconName.TEXT_AREA,
  [APIFieldType.TEXT]: IconName.TEXT_FORMAT,
  [APIFieldType.URL]: IconName.REFERENCE,
};

const FIELD_TYPES = Object.fromEntries(
  MAPPABLE_FIELD_TYPES.map(ft => [
    ft,
    {
      label: fieldOps.getFieldTypeLabel(ft),
      icon: FIELD_TYPE_ICONS[ft],
    },
  ])
);

export const getColumnTypeName = (columnType: ColumnMapping['columnType']) =>
  COLUMN_TYPES[columnType];

export const getReferenceFormatName = (
  format: ReferenceMapping['referenceFormat']
) => REFERENCE_FORMATS[format];

export const getParentFormatName = (format: ParentMapping['parentFormat']) =>
  PARENT_FORMATS[format];

export const isReferenceFormatDisabled = (
  format: ReferenceMapping['referenceFormat'],
  missingComponentsStrategy?: MissingComponentsStrategy
) =>
  missingComponentsStrategy === MissingComponentsStrategy.CREATE &&
  format !== 'path';

export const isParentFormatDisabled = (format: ParentMapping['parentFormat']) =>
  format === 'path';

export const getReferenceDirectionName = (
  direction: IntegrationReferenceDirection
) => REFERENCE_DIRECTIONS[direction];

export const getFieldTypeName = (fieldType: string) =>
  FIELD_TYPES[fieldType]?.label ?? '';

export const getFieldTypeIcon = (fieldType: string) =>
  FIELD_TYPES[fieldType]?.icon
    ? { size: IconSize.SMALL, name: FIELD_TYPES[fieldType]?.icon }
    : undefined;

export const getReferenceIfComponentIsMissingName = (
  missingComponentsStrategy: MissingComponentsStrategy
) => REFERENCE_IF_COMPONENT_IS_MISSING[missingComponentsStrategy];

export const getParentIfComponentIsMissingName = (
  MissingComponentsStrategy: MissingParentComponentsStrategy
) => PARENT_IF_COMPONENT_IS_MISSING[MissingComponentsStrategy];
