import { PrimaryButton } from '@ardoq/button';
import { viewModel$ } from './viewModel$';
import { connect } from '@ardoq/rxbeach';
import { ASYNC_STATUS } from 'integrations/common/types/api';
import {
  ArdoqLoaderComponent,
  LoaderColor,
  LoaderSize,
} from '@ardoq/ardoq-loader-component';
import { Box, FlexBox, Stack } from '@ardoq/layout';
import {
  EmptyState,
  EmptyStateNoResultIllustration,
  ErrorNotification,
} from '@ardoq/status-ui';
import { VisualMapperProps } from './types';
import { VisualMappings } from './VisualMappings';

const VisualMapperComponentWrapper = (props: VisualMapperProps) => {
  return (
    <FlexBox paddingTop="medium" paddingX="medium" flex={1}>
      <VisualMapperComponent {...props} />
    </FlexBox>
  );
};

const VisualMapperComponent = ({
  mappingsStatus,
  mappingsComponents,
  mappingsReferences,
  mappingsErrors,
  analyze,
  document,
  componentTypeOptions,
  referenceTypeOptions,
  componentsOptions,
  onAnalyze,
  onImport,
  onComponentMapping,
  onComponentTypeChange,
  onAddNewComponentMapping,
  onDeleteComponentMapping,
  onReferenceMapping,
  onAddNewReferenceMapping,
  onDeleteReferenceMapping,
}: VisualMapperProps) => {
  if (analyze.status === ASYNC_STATUS.INIT) {
    return (
      <FlexBox justify="center" align="center" width="full">
        <EmptyState
          description="Select your Lucidchart document and relevant workspaces. Click on Analyze to start the analysis of the Lucidchart document."
          layout="vertical"
          media={<EmptyStateNoResultIllustration />}
          title=""
        >
          <PrimaryButton
            isDisabled={document.status !== ASYNC_STATUS.SUCCESS}
            onClick={onAnalyze}
          >
            Analyze
          </PrimaryButton>
        </EmptyState>
      </FlexBox>
    );
  }

  if (analyze.status === ASYNC_STATUS.LOADING) {
    return (
      <FlexBox flex={1} justify="center" align="center">
        <ArdoqLoaderComponent
          loaderText="Analyzing Lucidchart document"
          loaderType="spinner"
          loaderColor={LoaderColor.BRAND}
          size={LoaderSize.MEDIUM}
        />
      </FlexBox>
    );
  }

  if (analyze.status === ASYNC_STATUS.FAILURE) {
    return (
      <Box width="full">
        <Stack gap="medium">
          <ErrorNotification>Failed to analyze the document.</ErrorNotification>
          <PrimaryButton onClick={onAnalyze}>Try again</PrimaryButton>
        </Stack>
      </Box>
    );
  }

  if (analyze.status === ASYNC_STATUS.SUCCESS) {
    return (
      <VisualMappings
        analyze={analyze}
        document={document}
        onAnalyze={onAnalyze}
        onImport={onImport}
        mappingsStatus={mappingsStatus}
        mappingsComponents={mappingsComponents}
        mappingsReferences={mappingsReferences}
        mappingsErrors={mappingsErrors}
        componentTypeOptions={componentTypeOptions}
        referenceTypeOptions={referenceTypeOptions}
        componentsOptions={componentsOptions}
        onComponentMapping={onComponentMapping}
        onComponentTypeChange={onComponentTypeChange}
        onAddNewComponentMapping={onAddNewComponentMapping}
        onDeleteComponentMapping={onDeleteComponentMapping}
        onReferenceMapping={onReferenceMapping}
        onAddNewReferenceMapping={onAddNewReferenceMapping}
        onDeleteReferenceMapping={onDeleteReferenceMapping}
      />
    );
  }

  return null;
};

export const VisualMapper = connect(VisualMapperComponentWrapper, viewModel$);
