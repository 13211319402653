import { connect } from '@ardoq/rxbeach';
import linkSvgOverlay$, { LinkSvgOverlayArgs } from './linkSvgOverlay$';
import styled from 'styled-components';

const CIRCLE_RADIUS = 4;
const LINK_LINE_WIDTH = 2;

const LinkSvgOverlay = ({
  linkPosition,
  linkSourceStartPosition,
  style,
  distance,
  color,
  isDirectionDown,
  svgHeight,
  svgWidth,
  shouldShow,
  navigatorContainerBox,
  hasLinkTarget,
}: LinkSvgOverlayArgs) => {
  if (!(shouldShow && linkSourceStartPosition)) {
    return null;
  }
  return (
    <OverlaySvg
      viewBox={`0 0 ${svgWidth} ${svgHeight}`}
      id="link-overlay"
      style={style}
    >
      {navigatorContainerBox && (
        <mask id="mask">
          <rect x={0} y={0} width={svgWidth} height={svgHeight} fill="white" />
          {isDirectionDown ? (
            <rect
              x={navigatorContainerBox.left}
              y={0}
              width={navigatorContainerBox.width}
              height={navigatorContainerBox.top}
              fill="black"
            />
          ) : (
            <rect
              x={navigatorContainerBox.left}
              y={navigatorContainerBox.bottom}
              width={navigatorContainerBox.width}
              height={svgHeight - navigatorContainerBox.bottom}
              fill="black"
            />
          )}
        </mask>
      )}
      <g fill={color} mask={navigatorContainerBox ? 'url(#mask)' : undefined}>
        {hasLinkTarget && (
          <circle r={CIRCLE_RADIUS} cx={linkPosition.x} cy={linkPosition.y} />
        )}

        <circle
          r={CIRCLE_RADIUS}
          cx={linkSourceStartPosition.x}
          cy={linkSourceStartPosition.y}
        />

        <path
          d={`
            M ${linkSourceStartPosition.x}, ${linkSourceStartPosition.y}
            A ${distance}, ${distance} 0 0, 1 ${linkPosition.x}, ${linkPosition.y}
          `}
          fill="none"
          stroke={color}
          strokeWidth={LINK_LINE_WIDTH}
        />
      </g>
    </OverlaySvg>
  );
};

const OverlaySvg = styled.svg`
  position: fixed;
  pointer-events: none;
  z-index: 10000;
`;

export default connect(LinkSvgOverlay, linkSvgOverlay$);
