import {
  MappedComponent,
  MappedReference,
  MappingErrors,
  MappingsState,
} from './types';

export const isValidComponentMapping = (component: MappedComponent) => {
  return (
    component.workspaceId && component.name.trim() && component.componentTypeId
  );
};

export const isValidReferenceMapping = (reference: MappedReference) => {
  return (
    reference.sourceComponentId &&
    reference.targetComponentId &&
    reference.referenceTypeId
  );
};

export const mappingErrors = (mappings: MappingsState): MappingErrors => {
  const componentsErrorIds = Object.values(mappings.components)
    .filter(c => !isValidComponentMapping(c))
    .map(({ id }) => id);

  const referencesErrorIds = Object.values(mappings.references)
    .filter(r => !isValidReferenceMapping(r))
    .map(({ id }) => id);

  return {
    hasErrors: referencesErrorIds.length > 0 || componentsErrorIds.length > 0,
    components: componentsErrorIds,
    references: referencesErrorIds,
  };
};

export const generateMappingId = () =>
  Math.random().toString(36).substring(2, 15);
