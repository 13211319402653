import {
  RichTextEditorExtension,
  defaultExtensions,
} from '@ardoq/rich-text-editor-2024';
import { augmentationsApi } from '@ardoq/api';
import { trackEvent } from 'tracking/tracking';
import {
  augmentationModal$,
  AugmentationModalState,
} from './augmentationModal$';
import { connect, dispatchAction } from '@ardoq/rxbeach';
import { combineLatest, map } from 'rxjs';
import {
  generateValueProposition,
  createValuePropositionComponent,
  setCompany,
  setDescription,
  setValueProposition,
  submitValuePropositionModal,
} from './actions';
import AugmentationModal from './AugmentationModal';
import useCaseLandingPage$ from '../useCaseLandingPage$';
import { UseCaseLandingPageState } from '../types';

type ValuePropositionModalViewProps = {
  company: string;
  setCompany: (value: string) => void;
  description: string;
  setDescription: (value: string) => void;
  showVP: boolean;
  valueProposition: string;
  setValueProposition: (value: string) => void;
  loading: boolean;
  handleSubmit: (closeModal: () => void) => void;
  handleGenerateValueProposition: () => void;
  handleGenerate?: () => void;
  toolbarExtensions: RichTextEditorExtension[];
  closeModal: () => void;
  disableGenerate: boolean;
  disableGenerateVP: boolean;
  disableSubmit: boolean;
  isGenerationError: boolean;
  isGenerationSuccess: boolean;
};

const ValuePropositionModalView = (props: ValuePropositionModalViewProps) => {
  return (
    <AugmentationModal
      {...props}
      modalDescription="Generate a Value Proposition for the Organization based on the Organization Name and Description. The generated Value Proposition will be saved in the Value Streams workspace."
      submitText="View Component"
      generateName="Component"
    />
  );
};

const toProps = (
  augmentState: AugmentationModalState,
  useCaseState: UseCaseLandingPageState
): Omit<ValuePropositionModalViewProps, 'closeModal'> => ({
  company: augmentState.company,
  setCompany: company => {
    dispatchAction(setCompany(company));
  },
  description: augmentState.description,
  setDescription: description => {
    dispatchAction(setDescription(description));
  },
  showVP: augmentState.showVP,
  valueProposition: augmentState.valueProposition,
  setValueProposition: vp => {
    dispatchAction(setValueProposition(vp));
  },
  loading: augmentState.loading,
  disableGenerate: augmentState.disableGenerate,
  disableSubmit: augmentState.disableSubmit,
  disableGenerateVP: augmentState.disableGenerateValueProposition,
  handleGenerate: augmentState.showVP
    ? () => {
        dispatchAction(
          createValuePropositionComponent({
            valueProposition: augmentState.valueProposition,
            useCaseId: useCaseState.selectedUseCaseId,
          })
        );
      }
    : undefined,
  handleSubmit: closeModal => {
    dispatchAction(
      submitValuePropositionModal({
        closeModal,
        link: augmentState.generationLink,
      })
    );
  },
  handleGenerateValueProposition: () => {
    dispatchAction(
      generateValueProposition({
        company: augmentState.company,
        description: augmentState.description,
      })
    );
  },
  isGenerationError: augmentState.generationError,
  isGenerationSuccess: Boolean(augmentState.generationLink),
  toolbarExtensions: [
    ...defaultExtensions,
    {
      name: 'Generate',
      generateFunction: () =>
        augmentationsApi.generateDescription({
          name: augmentState.company,
          description: augmentState.description,
        }),
      trackEventFunction: trackEvent,
    },
  ],
});

export default connect(
  ValuePropositionModalView,
  combineLatest([augmentationModal$, useCaseLandingPage$]).pipe(
    map(([augmentationModal, useCaseLandingPage]) =>
      toProps(augmentationModal, useCaseLandingPage)
    )
  )
);
