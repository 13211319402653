import tabOptions$ from './tabOptions$';
import { MAIN_PANE_LEFT, MetaInfo } from 'streams/views/mainContent/types';
import { ArdoqId, ViewIds } from '@ardoq/api-types';
import {
  ExtractPayload,
  action$,
  dispatchAction,
  reducer,
  persistentReducedStream,
  reduceState,
  streamReducer,
} from '@ardoq/rxbeach';
import {
  PayloadActiveTab,
  allWorkspacesClosed,
  setActiveMainTabLeft,
  setActiveMainTabRight,
} from 'streams/views/mainContent/actions';
import { distinctUntilChanged, shareReplay, tap } from 'rxjs/operators';
import { ActiveTabsAndTabOptions, Tab, TabOptions } from './types';
import {
  allWorkspacesClosedHandler,
  getRightTabId,
  isDisabledTab,
  keepCurrentActiveTabs,
  metaInfoListToTabs,
  setActiveTab,
  setStartViewActive,
} from './tabUtils';
import { isEqual } from 'lodash';
import { closePane, requestSaveViews, splitPane } from './actions';
import { ContextShape } from '@ardoq/data-model';
import { contextWorkspaceId$ } from 'streams/context/context$';
import { currentUserInterface } from 'modelInterface/currentUser/currentUserInterface';
import { workspaceAccessControlInterface } from 'resourcePermissions/accessControlHelpers/workspace';
import { isViewRestrictedByQuickStart } from 'quickStart/isViewRestrictedByQuickStart';
import {
  isViewDiscontinued,
  isViewRestrictedInWorkspaceMode,
  VIEW_REPLACEMENTS_IN_WORKSPACE_MODE,
} from 'viewDeprecation/restrictedViews';
import { getActiveScenarioState } from 'streams/activeScenario/activeScenario$';
import { isViewpointMode$ } from 'traversals/loadedGraph$';
import { isPresentationMode } from 'appConfig';

/*
  The logic to manage the default views and to select the active view is
  separated in two main use cases:
    * Open the first workspace
    * Open an additional workspace or close an open workspace of a set of
      open workspaces


  Open the first workspace
  ------------------------
  This can be either the very first time to open a workspace or after closing
  all workspaces to open another workspace during a session. There are three
  views to be considered:

    * The last active view during the session
    * The start view
    * The first view of all selected views (of all tabs). This list is the
      pages view plus the default views stored on the active workspace.
      The first one is always the pages view. The order is hardcoded, given by
      the order in metaInfoTabs.

  In this situation the precedence for the first (or main) pane is:
    * Start view (perhaps it’s not in the default views, then it has to be added)
    * Previous active view if that view is in the default views
    * First view of all selected views (pages view)

  Precedence for the second pane:
    * The first tab of the selected views which is not the same view as in the
      main pane.

  Open an additional workspace or close the active workspace of a set of open workspaces
  --------------------------------------------------------------------------------------
  This is for all situations the same: keep all active views (first and optional
  secondary pane) and add them to the default view of the newly active workspace
  (if they are missing).

*/

const setActiveMainTabLeftReducer = (
  state: ActiveTabsAndTabOptions,
  { activeTabId }: PayloadActiveTab
) => {
  if (activeTabId === state.activeTabIdLeft || isDisabledTab(activeTabId)) {
    return state;
  }
  const previousActiveTabIdLeft = state.activeTabIdLeft;
  return {
    ...state,
    activeTabIdLeft: activeTabId,
    previousActiveTabIdLeft,
  };
};
const handleSetActiveMainTabLeft = reducer<
  ActiveTabsAndTabOptions,
  PayloadActiveTab
>(setActiveMainTabLeft, setActiveMainTabLeftReducer);

const setActiveMainTabRightReducer = (
  state: ActiveTabsAndTabOptions,
  { activeTabId }: PayloadActiveTab
) => {
  if (activeTabId === state.activeTabIdRight || isDisabledTab(activeTabId)) {
    return state;
  }
  return {
    ...state,
    activeTabIdRight: activeTabId,
  };
};
const handleSetActiveMainTabRight = reducer<
  ActiveTabsAndTabOptions,
  PayloadActiveTab
>(setActiveMainTabRight, setActiveMainTabRightReducer);

type TabOptionsStreamShape = {
  tabs: Tab[];
  startView: ViewIds;
  activeWorkspaceId: ArdoqId;
  metaInfoList: MetaInfo[];
  savedViews: ViewIds[];
  canEditWorkspaceByPermissions: boolean;
};
const tabOptionsReducer = (
  {
    activeTabIdLeft,
    previousActiveTabIdLeft,
    activeTabIdRight,
  }: ActiveTabsAndTabOptions,
  {
    tabs,
    startView,
    activeWorkspaceId,
    metaInfoList,
    savedViews,
    canEditWorkspaceByPermissions,
  }: TabOptionsStreamShape
) => {
  if (tabs.length === 0 || metaInfoList.length === 0) {
    return {
      tabs,
      activeTabIdLeft,
      activeTabIdRight,
      previousActiveTabIdLeft,
      metaInfoList,
      canEditWorkspaceByPermissions,
    };
  }

  const tabIds = new Set(tabs.map(({ id }) => id));

  if (activeTabIdLeft === ViewIds.NONE) {
    if (startView !== ViewIds.NONE) {
      return setStartViewActive({
        tabs,
        startView,
        activeTabIdLeft,
        activeTabIdRight,
        metaInfoList,
        activeWorkspaceId,
        savedViews,
        canEditWorkspaceByPermissions,
      });
    }

    const newActiveTabIdLeft =
      previousActiveTabIdLeft && tabIds.has(previousActiveTabIdLeft)
        ? previousActiveTabIdLeft
        : tabs.length > 0
          ? tabs[0].id
          : ViewIds.NONE;

    return {
      tabs,
      activeTabIdLeft: newActiveTabIdLeft,
      activeTabIdRight,
      previousActiveTabIdLeft: activeTabIdLeft,
      metaInfoList,
    };
  }

  return keepCurrentActiveTabs({
    activeViews: [activeTabIdLeft, activeTabIdRight].filter(
      id => id !== ViewIds.NONE
    ),
    activeTabIdLeft,
    activeTabIdRight,
    previousActiveTabIdLeft,
    metaInfoList,
    tabs,
    activeWorkspaceId,
    savedViews,
    canEditWorkspaceByPermissions,
  });
};
const handleTabOptionsChanged = streamReducer<
  ActiveTabsAndTabOptions,
  TabOptionsStreamShape
>(tabOptions$, tabOptionsReducer);

const handleAllWorkspacesClosed = reducer<ActiveTabsAndTabOptions>(
  allWorkspacesClosed,
  allWorkspacesClosedHandler
);

const splitPaneReducer = (state: ActiveTabsAndTabOptions) => ({
  ...state,
  activeTabIdRight: getRightTabId(state.tabs, state.activeTabIdLeft),
});
const handleSplitPane = reducer<ActiveTabsAndTabOptions>(
  splitPane,
  splitPaneReducer
);

/**
 * Things like e.g. scenarios are launching the workspace mode but don't interact with active views in any way.
 * To prevent the restricted views from being displayed in these cases, we filter and replace the active tab
 * left when isViewpointMode changes to false.
 *
 * We can omit this operation in presentation mode, as the active tab is set by the loaded slide.
 */
const viewpointModeViewClosedReducer = (
  state: ActiveTabsAndTabOptions,
  { isViewpointMode }: { isViewpointMode: boolean }
) => {
  const prevActiveTabLeftId = state.activeTabIdLeft;
  if (!prevActiveTabLeftId || isViewpointMode || isPresentationMode()) {
    return state;
  }

  return {
    ...state,
    activeTabIdLeft:
      VIEW_REPLACEMENTS_IN_WORKSPACE_MODE.get(prevActiveTabLeftId) ??
      prevActiveTabLeftId,
  };
};
const handleViewpointModeViewClosed = streamReducer(
  isViewpointMode$,
  viewpointModeViewClosedReducer
);

const closePaneReducer = (
  state: ActiveTabsAndTabOptions,
  { location }: ExtractPayload<typeof closePane>
) => {
  const activeTabIdLeft =
    location === MAIN_PANE_LEFT
      ? state.activeTabIdRight
      : state.activeTabIdLeft;
  const activeTabIdRight = ViewIds.NONE;

  return {
    ...state,
    activeTabIdLeft,
    activeTabIdRight,
  };
};
const handleClosePane = reducer<
  ActiveTabsAndTabOptions,
  ExtractPayload<typeof closePane>
>(closePane, closePaneReducer);

const activeTabsAndTabOptions$ = action$.pipe(
  reduceState(
    'activeTabsAndTabOptions$',
    {
      tabs: [],
      activeTabIdLeft: ViewIds.NONE,
      activeTabIdRight: ViewIds.NONE,
      previousActiveTabIdLeft: ViewIds.NONE,
      metaInfoList: [],
    },
    [
      handleSetActiveMainTabLeft,
      handleSetActiveMainTabRight,
      handleTabOptionsChanged,
      handleAllWorkspacesClosed,
      handleClosePane,
      handleSplitPane,
      handleViewpointModeViewClosed,
    ]
  ),
  distinctUntilChanged<ActiveTabsAndTabOptions>(isEqual),
  tap(async ({ needsToBeSaved, activeWorkspaceId: workspaceId, tabs }) => {
    if (needsToBeSaved && workspaceId) {
      const views = tabs.map(({ id }) => id);
      dispatchAction(requestSaveViews({ workspaceId, views }));
    }
  }),
  shareReplay({ bufferSize: 1, refCount: true })
);

type ComposeTabsToBeDisplayedArgs = {
  tabs: Tab[];
  activeTabId: ViewIds;
  metaInfoList: MetaInfo[];
};
/**
 * The `tabs` array contains only views saved in `workspace.views`. When we load a slide, the slide view might
 * be not in workspace anymore. We need to display the active tab nonetheless, but the selectView dialog should
 * not display this view. Therefore we filter here, and not in tabOptions$.
 *
 * Here, metaInfoListToTabs(metaInfoList) === "all tabs that exist in metaInfoTabs"
 */
type CanDisplayTabArgs = {
  isActive: boolean;
  id: ViewIds;
  workspaceViewIds: Set<ViewIds>;
};
const canDisplayTab = ({
  id,
  isActive,
  workspaceViewIds,
}: CanDisplayTabArgs) => {
  if (isActive) {
    return true;
  }

  if (isViewRestrictedByQuickStart(id) || isViewRestrictedInWorkspaceMode(id)) {
    return false;
  }

  if (!isViewDiscontinued(id) && workspaceViewIds.has(id)) {
    return true;
  }

  return false;
};

const composeTabsToBeDisplayed = ({
  tabs,
  activeTabId,
  metaInfoList,
}: ComposeTabsToBeDisplayedArgs) => {
  const workspaceViewIds = new Set(tabs.map(({ id }) => id));
  return {
    tabs: setActiveTab(metaInfoListToTabs(metaInfoList), activeTabId).filter(
      ({ id, isActive }) => canDisplayTab({ id, isActive, workspaceViewIds })
    ),
    activeTabId,
  };
};

const getHandleActiveTabsAndTabOptionsReducer =
  (activeTabField: 'activeTabIdLeft' | 'activeTabIdRight') =>
  (
    state: TabOptions,
    {
      tabs,
      [activeTabField]: activeTabId,
      metaInfoList,
    }: ActiveTabsAndTabOptions
  ): TabOptions => ({
    ...state,
    ...composeTabsToBeDisplayed({ tabs, activeTabId, metaInfoList }),
  });

const updateShowManageViews = (
  state: TabOptions,
  { workspaceId }: ContextShape
): TabOptions => ({
  ...state,
  showManageViews: workspaceAccessControlInterface.canEditWorkspace(
    currentUserInterface.getPermissionContext(),
    workspaceId,
    getActiveScenarioState()
  ),
});

const DEFAULT_TAB_OPTIONS = {
  tabs: [],
  activeTabId: ViewIds.NONE,
  showManageViews: false,
};

const handleActiveTabsAndTabOptionsLeft =
  getHandleActiveTabsAndTabOptionsReducer('activeTabIdLeft');
const handleActiveTabsAndTabOptionsRight =
  getHandleActiveTabsAndTabOptionsReducer('activeTabIdRight');

export const leftTabOptions$ = persistentReducedStream<TabOptions>(
  'leftTabOptions$',
  DEFAULT_TAB_OPTIONS,
  [
    streamReducer(activeTabsAndTabOptions$, handleActiveTabsAndTabOptionsLeft),
    streamReducer(contextWorkspaceId$, updateShowManageViews),
  ]
);

export const rightTabOptions$ = persistentReducedStream<TabOptions>(
  'rightTabOptions$',
  DEFAULT_TAB_OPTIONS,
  [
    streamReducer(activeTabsAndTabOptions$, handleActiveTabsAndTabOptionsRight),
    streamReducer(contextWorkspaceId$, updateShowManageViews),
  ]
);

export const getTabOptionsStreamWithLocation = (location: string) =>
  location === MAIN_PANE_LEFT ? leftTabOptions$ : rightTabOptions$;
