import { persistentReducedStream, reducer } from '@ardoq/rxbeach';
import { EMPTY, map, Observable } from 'rxjs';
import { IntegrationId } from 'integrations/common/streams/tabularMappings/types';
import {
  InstanceToTransferState,
  TransferDirection,
  TransferState,
} from 'integrations/common/streams/transferState/types';
import {
  transferFailure,
  startTransfer,
  transferSuccess,
  resetTransfer,
  setTransferDirection,
  updateTabularMapping,
  setInfoMessage,
  setFetchSelectionDataSuccess,
  updateSelectedWorkspaces,
} from 'integrations/common/streams/transferState/actions';
import { Maybe } from '@ardoq/common-helpers';
import _ from 'lodash/fp';
import { tablesComplaints } from 'integrations/common/streams/transferState/utils';
import { withoutEmptyTableMappings } from 'integrations/common/streams/tabularMappings/utils';
import { buildInitialStreamState } from '../utils';
import { ActionCreatorParameter } from '../../utils/actionCreatorWithIntegrationId';

export const initialInstanceState: TransferState = {
  requestStatus: 'INIT',
  dryRun: true,
  async: true,
  transferDirection: TransferDirection.IMPORT,
  infoMessage: null,
  isSelectionDataFetched: false,
};

const defaultState: InstanceToTransferState =
  buildInitialStreamState<TransferState>(() => initialInstanceState);

const transferSuccessReducer = (
  currentState: InstanceToTransferState,
  {
    integrationId,
    response,
    dryRun,
  }: ActionCreatorParameter<typeof transferSuccess>
) => {
  // TODO: https://ardoqcom.atlassian.net/browse/ARD-19393
  // Currently both dryRun and actual import results are stored in the same place
  // This is a temporary solution to avoid breaking the UI
  // We should store the dryRun and actual import results separately
  if (dryRun) {
    return {
      ...currentState,
      [integrationId]: {
        ...currentState[integrationId],
        requestStatus: 'SUCCESS',
        response,
        tablesComplaints: tablesComplaints(response.tables),
        selectedWorkspaces: response.affectedWorkspaces
          .filter(ws => Boolean(ws.id))
          .map(ws => ws.id),
      },
    };
  }

  // TODO: https://ardoqcom.atlassian.net/browse/ARD-19393
  // Here we keep the previous dryRun results
  // by only merging the requestStatus, tablesComplaints and affectedWorkspaces
  return _.update(
    integrationId,
    s =>
      _.merge(s, {
        requestStatus: 'SUCCESS',
        tablesComplaints: tablesComplaints(response.tables),
        response: {
          ...s.response,
          affectedWorkspaces: _.unionBy(
            'id',
            s.response.affectedWorkspaces,
            response.affectedWorkspaces
          ),
        },
        selectedWorkspaces: _.union(
          s.selectedWorkspaces,
          response.affectedWorkspaces
            .filter(ws => Boolean(ws.id))
            .map(ws => ws.id)
        ),
      }),
    currentState
  );
};
export const handleTransferSuccess = reducer<
  InstanceToTransferState,
  ActionCreatorParameter<typeof transferSuccess>
>(transferSuccess, transferSuccessReducer);

const updateTabularMappingReducer = (
  currentState: InstanceToTransferState,
  {
    integrationId,
    tabularMapping,
  }: ActionCreatorParameter<typeof updateTabularMapping>
) => {
  return _.update(
    integrationId,
    s => ({
      ...s,
      tabularMapping,
    }),
    currentState
  );
};
const handleUpdateTabularMapping = reducer<
  InstanceToTransferState,
  ActionCreatorParameter<typeof updateTabularMapping>
>(updateTabularMapping, updateTabularMappingReducer);

const setTransferDirectionReducer = (
  currentState: InstanceToTransferState,
  {
    transferDirection,
    integrationId,
  }: ActionCreatorParameter<typeof setTransferDirection>
) => {
  return _.update(
    integrationId,
    s => ({
      ..._.merge(s, {
        transferDirection,
      }),
    }),
    currentState
  );
};
const handleSetTransferDirection = reducer<
  InstanceToTransferState,
  ActionCreatorParameter<typeof setTransferDirection>
>(setTransferDirection, setTransferDirectionReducer);

const startTransferReducer = (
  currentState: InstanceToTransferState,
  {
    integrationId,
    tabularMapping,
    dryRun,
    async,
    isSelectionDataFetched = false,
  }: ActionCreatorParameter<typeof startTransfer>
) =>
  _.update(
    integrationId,
    s => ({
      requestStatus: 'LOADING',
      dryRun,
      async,
      tabularMapping: withoutEmptyTableMappings(tabularMapping),
      // TODO: https://ardoqcom.atlassian.net/browse/ARD-19393
      // keeping the previous response
      response: s.response,
      transferDirection: s.transferDirection,
      infoMessage: null,
      isSelectionDataFetched,
      selectedWorkspaces: s.selectedWorkspaces,
    }),
    currentState
  );
export const handleStartTransfer = reducer<
  InstanceToTransferState,
  ActionCreatorParameter<typeof startTransfer>
>(startTransfer, startTransferReducer);

const transferFailureReducer = (
  currentState: InstanceToTransferState,
  {
    integrationId,
    errorMessage,
    errorData,
  }: ActionCreatorParameter<typeof transferFailure>
) =>
  _.update(
    integrationId,
    s => _.merge(s, { requestStatus: 'FAILURE', errorMessage, errorData }),
    currentState
  );
export const handleTransferFailure = reducer<
  InstanceToTransferState,
  ActionCreatorParameter<typeof transferFailure>
>(transferFailure, transferFailureReducer);

const resetTransferReducer = (
  currentState: InstanceToTransferState,
  {
    integrationId,
    resetTransferDirection = true,
  }: ActionCreatorParameter<typeof resetTransfer>
): InstanceToTransferState => ({
  ...currentState,
  [integrationId]: {
    ...initialInstanceState,
    ...(!resetTransferDirection && {
      transferDirection: currentState[integrationId].transferDirection,
      isSelectionDataFetched:
        currentState[integrationId].isSelectionDataFetched,
    }),
  },
});

export const handleResetTransfer = reducer<
  InstanceToTransferState,
  ActionCreatorParameter<typeof resetTransfer>
>(resetTransfer, resetTransferReducer);

const setInfoMessageReducer = (
  currentState: InstanceToTransferState,
  { integrationId, infoMessage }: ActionCreatorParameter<typeof setInfoMessage>
): InstanceToTransferState =>
  _.update(
    integrationId,
    s => ({
      ..._.merge(s, {
        infoMessage,
      }),
    }),
    currentState
  );

const handleSetInfoMessage = reducer<
  InstanceToTransferState,
  ActionCreatorParameter<typeof setInfoMessage>
>(setInfoMessage, setInfoMessageReducer);

const setFetchSelectionDataSuccessReducer = (
  currentState: InstanceToTransferState,
  { integrationId }: ActionCreatorParameter<typeof setFetchSelectionDataSuccess>
): InstanceToTransferState =>
  _.update(
    integrationId,
    s => ({
      ..._.merge(s, {
        isSelectionDataFetched: true,
      }),
    }),
    currentState
  );

const handleSetFetchSelectionDataSuccess = reducer<
  InstanceToTransferState,
  ActionCreatorParameter<typeof setFetchSelectionDataSuccess>
>(setFetchSelectionDataSuccess, setFetchSelectionDataSuccessReducer);
const updateSelectedWorkspacesReducer = (
  currentState: InstanceToTransferState,
  {
    integrationId,
    selectedWorkspaces,
  }: ActionCreatorParameter<typeof updateSelectedWorkspaces>
) => ({
  ...currentState,
  [integrationId]: {
    ...currentState[integrationId],
    selectedWorkspaces: selectedWorkspaces,
  },
});

export const transferState$ = persistentReducedStream(
  `transferState$`,
  defaultState,
  [
    handleTransferSuccess,
    handleUpdateTabularMapping,
    handleStartTransfer,
    handleTransferFailure,
    handleResetTransfer,
    handleSetTransferDirection,
    handleSetInfoMessage,
    handleSetFetchSelectionDataSuccess,
    reducer(updateSelectedWorkspaces, updateSelectedWorkspacesReducer),
  ]
);

const integrationToTransferStateStream = new Map<
  IntegrationId,
  Observable<TransferState>
>();

export const getTransferStateStream = (integrationId: Maybe<IntegrationId>) => {
  if (!integrationId) {
    return EMPTY;
  }

  const transferStateStream =
    integrationToTransferStateStream.get(integrationId);

  if (transferStateStream) {
    return transferStateStream;
  }

  const stream$ = transferState$.pipe(
    map(transferState => transferState[integrationId])
  );

  integrationToTransferStateStream.set(integrationId, stream$);

  return stream$;
};
