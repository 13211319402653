import { getActiveIntegrationStream } from 'integrations/common/streams/activeIntegrations/activeIntegrations$';
import { combineLatest, Observable, switchMap } from 'rxjs';
import { getTransferStateStream } from 'integrations/common/streams/transferState/getTransferStateStream';
import { transferConfigs$ } from '../../streams/transferConfigs/transferConfigs$';
import { map } from 'rxjs/operators';
import { getCurrentTransferConfig } from '../../streams/transferConfigs/utils';
import { tabularMappingIntoTransferConfig } from '../../streams/tabularMappings/utils';
import { FailureState } from 'integrations/common/streams/transferState/types';
import { integrationId$ } from 'integrations/common/streams/integrationId/integrationId$';
import { getIntegrationTermsDictionaryStream } from 'integrations/common/streams/integrationTermsDictionary/getIntegrationTermsDictionaryStream';

export const viewModel$ = integrationId$.pipe(
  switchMap(integrationId => {
    return combineLatest([
      getTransferStateStream(integrationId) as Observable<FailureState>,
      transferConfigs$,
      getActiveIntegrationStream(integrationId),
      getIntegrationTermsDictionaryStream(integrationId),
    ]);
  }),
  map(
    ([
      { tabularMapping, errorMessage, transferDirection, errorData },
      transferConfigs,
      activeIntegration,
      integrationTermsDictionary,
    ]) => {
      const transferConfig = getCurrentTransferConfig(
        transferConfigs.configs,
        activeIntegration
      );
      const config = tabularMappingIntoTransferConfig(
        tabularMapping,
        transferConfig
      );

      return {
        errorMessage,
        errorData,
        integrationName: integrationTermsDictionary.name,
        config: {
          ...config,
          name: config.name || activeIntegration.integrationName,
        },
        transferDirection,
      };
    }
  )
);
