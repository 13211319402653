import { FlexBox } from '@ardoq/layout';
import { PageBody, PageTopbar, PageWrapper } from '@ardoq/page-layout';
import { DocumentSelector } from './DocumentSelector/DocumentSelector';
import { dispatchAction } from '@ardoq/rxbeach';
import { VisualMapper } from './VisualMapper/VisualMapper';
import { GhostButton } from '@ardoq/button';
import { restartImport } from './streams/configs/actions';
import { WorkspacesSelector } from './WorkspacesSelector/WorkspacesSelector';
import styled from 'styled-components';
import { Icon, IconName } from '@ardoq/icons';
import { setVisibleIntegration } from 'integrations/actions';

const SidebarContainer = styled(FlexBox)`
  overflow-y: scroll;
`;

export const Lucidchart = () => {
  return (
    <PageWrapper>
      <PageTopbar
        primaryContent="Lucidchart visual importer"
        secondaryContent="Import and integrations"
        toolbarContent={
          <FlexBox gap="medium">
            <GhostButton
              onClick={() => {
                dispatchAction(setVisibleIntegration({ id: null }));
              }}
            >
              <Icon iconName={IconName.CHEVRON_LEFT} />
              Back to overview
            </GhostButton>
            <GhostButton
              onClick={() => {
                dispatchAction(restartImport());
              }}
            >
              Restart
            </GhostButton>
          </FlexBox>
        }
      />
      <PageBody
        leftContent={
          <SidebarContainer
            flexDirection="column"
            height="full"
            backgroundColor="bgDefault"
            borderColor="borderSubtle00"
            maxWidth="min(700px, 40%)"
            width="full"
            minWidth="540px"
          >
            <WorkspacesSelector />
            <VisualMapper />
          </SidebarContainer>
        }
      >
        <DocumentSelector />
      </PageBody>
    </PageWrapper>
  );
};
