import styled from 'styled-components';
import broadcast$ from 'broadcasts/broadcast$';
import { dispatchAction, connect } from '@ardoq/rxbeach';
import {
  PrimaryButton,
  SecondaryButton,
  GhostButton,
  TertiaryButton,
} from '@ardoq/button';
import { navigateToBroadcastOverview } from 'router/navigationActions';
import {
  initiateSavingCurrentBroadcast,
  openPreviewBroadcastDialog,
  showLaunchCurrentBroadcastDialog,
} from 'broadcasts/actions';
import { isEqual } from 'lodash';
import {
  doesCurrentBroadcastHaveUnsavedProgress,
  isReportContent,
} from 'broadcasts/utils';
import { distinctUntilChanged, map } from 'rxjs/operators';
import { BroadcastSaveStatus, BroadcastStreamShape } from 'broadcasts/types';
import { s8 } from '@ardoq/design-tokens';
import KnowledgeBaseIcon from 'broadcasts/components/KnowledgeBaseIcon';
import { trackClickedOnGoToBroadcastOverviewInBroadcastBuilder } from 'broadcasts/tracking';
import { VisibilityIcon } from '@ardoq/icons';
import { trackEvent } from '../../tracking/tracking';
import { Header2 } from '@ardoq/typography';
import { FlexBox } from '@ardoq/layout';
import { TextOverflow } from '@ardoq/popovers';

type LeftHeaderContentProps = {
  broadcastName: string;
};

export const LeftHeaderContent = ({
  broadcastName,
}: LeftHeaderContentProps) => (
  <FlexBox align="center" flexGrow={1} justify="flex-start" gap="xsmall">
    <KnowledgeBaseIcon />
    <TextOverflow style={{ maxWidth: 600 }}>
      <Header2>- {broadcastName}</Header2>
    </TextOverflow>
  </FlexBox>
);

const RightAligned = styled.div`
  display: flex;
  flex-grow: 1;
  min-width: fit-content;
  justify-content: flex-end;
  gap: ${s8};
`;

type RightHeaderContentProps = {
  hasUnsavedProgress: boolean;
  isSaving: boolean;
  isReportBroadcast: boolean;
};

const RightHeaderContent = ({
  hasUnsavedProgress,
  isSaving,
  isReportBroadcast,
}: RightHeaderContentProps) => {
  return (
    <RightAligned>
      <GhostButton
        onClick={() => {
          trackClickedOnGoToBroadcastOverviewInBroadcastBuilder();
          dispatchAction(navigateToBroadcastOverview());
        }}
        dataTestId="broadcast-overview-button"
      >
        Go to broadcast overview
      </GhostButton>
      <TertiaryButton
        onClick={() => {
          trackEvent('open-broadcast-preview-dialog');
          dispatchAction(openPreviewBroadcastDialog());
        }}
        dataTestId="open-broadcast-preview-dialog"
        isDisabled={isReportBroadcast}
      >
        <VisibilityIcon />
        Preview
      </TertiaryButton>
      <SecondaryButton
        isDisabled={!hasUnsavedProgress}
        onClick={() => dispatchAction(initiateSavingCurrentBroadcast())}
        dataTestId="save-button"
      >
        Save
      </SecondaryButton>
      <PrimaryButton
        isDisabled={isSaving}
        dataTestId="launch-button"
        onClick={() => dispatchAction(showLaunchCurrentBroadcastDialog())}
      >
        Launch Broadcast
      </PrimaryButton>
    </RightAligned>
  );
};

const toProps = (state: BroadcastStreamShape) => ({
  hasUnsavedProgress: doesCurrentBroadcastHaveUnsavedProgress(state),
  isSaving: state.saveStatus === BroadcastSaveStatus.SAVING,
  isReportBroadcast:
    state.currentBroadcast && isReportContent(state.currentBroadcast.content),
});

export default connect(
  RightHeaderContent,
  broadcast$.pipe(map(toProps), distinctUntilChanged(isEqual))
);
